import { InfoIcon } from '@chakra-ui/icons';
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Checkbox,
  Divider,
  FormControl,
  FormLabel,
  HStack,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Radio,
  Skeleton,
  Stack,
  Text,
  Tooltip,
  VStack,
  useRadioGroup,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Select } from 'chakra-react-select';
import { useEffect, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import { noop, routes } from '@blockpulse3/data/shared';
import {
  AssetType,
  CompanyCapitalType,
  SecondaryMarketType,
  useGetAssetsByCompanyQuery,
  useGetCompanyQuery,
} from '@blockpulse3/graphql/hooks';
import { TransferIcon, WithdrawalIcon } from '@blockpulse3/ui/assets';
import { ErrorMessage } from '@blockpulse3/ui/commons';

import {
  secondaryOperationInformationsDefaults,
  secondaryOperationInformationsSchema,
} from './schema';
import { ISecondaryOperationInformationsForm } from './types';

type Props = {
  defaultValues?: ISecondaryOperationInformationsForm;
  isLoading?: boolean;
  onSubmit?: (data: ISecondaryOperationInformationsForm) => void;
  isRelatedToOpportunity?: boolean;
};

/**
 * NewSecondaryOperationInformations.
 *
 * @returns {JSX.Element}
 */
export function NewSecondaryOperationInformations({
  defaultValues = secondaryOperationInformationsDefaults,
  isLoading = false,
  onSubmit = noop,
  isRelatedToOpportunity = false,
}: Props): JSX.Element {
  const t = useTranslations();

  const { companyId = '' } = useParams();
  const { data } = useGetAssetsByCompanyQuery({ variables: { companyId }, skip: !companyId });
  const companyRep = useGetCompanyQuery({ variables: { companyId }, skip: !companyId });
  const [displayRadioFull, setDisplayRadioFull] = useState<boolean>(false);

  const capitalType = companyRep?.data?.company.capitalType;

  const {
    register,
    watch,
    control,
    formState,
    handleSubmit,
    reset,
    setValue: setFormValue,
  } = useForm<ISecondaryOperationInformationsForm>({
    defaultValues,
    resolver: yupResolver(secondaryOperationInformationsSchema),
  });
  const isPromiseToSell = watch('isPromiseToSell');
  const enableSellerInvitation = watch('enableSellerInvitation');

  useEffect(() => {
    reset({ ...defaultValues });
  }, [defaultValues, reset]);

  useEffect(() => {
    if (isPromiseToSell) {
      setFormValue('enableSellerInvitation', true);
    }
  }, [isPromiseToSell, setFormValue]);

  const handleRadioChange = (nextValue: string): void => {
    setRadioValue(nextValue);
    setFormValue('secondaryMarketType', nextValue);
  };

  const {
    getRootProps,
    getRadioProps,
    setValue: setRadioValue,
  } = useRadioGroup({
    name: 'secondaryMarketType',
    defaultValue: defaultValues.secondaryMarketType,
    onChange: handleRadioChange,
  });

  useEffect(() => {
    if (capitalType === CompanyCapitalType.VARIABLE) {
      setDisplayRadioFull(true);
    }

    if (capitalType === CompanyCapitalType.FIXED) {
      setRadioValue(SecondaryMarketType.TRANSFER_MARKET);
      setFormValue('secondaryMarketType', SecondaryMarketType.TRANSFER_MARKET);
    }
  }, [capitalType]);

  const assets = data?.getAssetsByCompany || [];

  const options = assets
    .filter((asset) =>
      [AssetType.ORDINARY_SHARE, AssetType.PREFERRED_SHARE].includes(asset.assetType),
    )
    .map(({ name, id }) => ({
      label: name,
      value: id,
    }));

  const navigate = useNavigate();

  const handleFormSubmit: SubmitHandler<ISecondaryOperationInformationsForm> = (data): void => {
    onSubmit(data);
  };

  const handleFormCancel = (): void => {
    navigate(generatePath(routes.company.href, { companyId }));
  };

  return (
    <Card variant="divider-top" width="full">
      <CardHeader>
        <Heading size="lg">{t('Settings')}</Heading>
      </CardHeader>
      <Divider />
      <CardBody>
        <form id="new-secondary-operation" onSubmit={handleSubmit(handleFormSubmit)}>
          <Stack spacing="4">
            {companyRep.loading ? (
              <Skeleton height="85px" width="full" />
            ) : (
              <FormControl isInvalid={!!formState.errors.secondaryMarketType}>
                <Stack direction={{ base: 'column', md: 'row' }} spacing="5" {...getRootProps()}>
                  <Radio {...getRadioProps({ value: 'TRANSFER_MARKET' })} variant="switcher">
                    <HStack alignItems="center" py="2" spacing="3">
                      <TransferIcon boxSize="46px" />
                      <VStack alignItems="start">
                        <Text className="title">{t('SecondaryMarketTransferTitle')}</Text>
                        <Text color="gray.500" fontSize="sm">
                          {t('SecondaryMarketTransferSubtitle')}
                        </Text>
                      </VStack>
                    </HStack>
                  </Radio>
                  {displayRadioFull && (
                    <Radio
                      {...getRadioProps({ value: 'WITHDRAWAL_MARKET' })}
                      isDisabled={isRelatedToOpportunity}
                      variant="switcher"
                    >
                      <HStack alignItems="center" py="2" spacing="3">
                        <WithdrawalIcon boxSize="46px" />
                        <VStack alignItems="start">
                          <Text className="title">{t('SecondaryMarketWithdrawalTitle')}</Text>
                          <Text color="gray.500" fontSize="sm">
                            {t('SecondaryMarketWithdrawalSubtitle')}
                          </Text>
                        </VStack>
                      </HStack>
                    </Radio>
                  )}
                </Stack>
                <ErrorMessage error={formState.errors.secondaryMarketType} />
              </FormControl>
            )}
            <FormControl isInvalid={!!formState.errors.name}>
              <FormLabel htmlFor="name">{t('OperationName')}</FormLabel>
              <Input id="name" type="string" {...register('name')} />
              <ErrorMessage error={formState.errors.name} />
            </FormControl>
            <Stack alignItems="flex-start" direction={{ base: 'column', md: 'row' }} spacing="4">
              <FormControl isInvalid={!!formState.errors.startSubscriptionDate}>
                <FormLabel htmlFor="startSubscriptionDate">{t('OperationStartDate')}</FormLabel>
                <Input
                  id="startSubscriptionDate"
                  type="date"
                  {...register('startSubscriptionDate')}
                />
                <ErrorMessage error={formState.errors.startSubscriptionDate} />
              </FormControl>
              <FormControl isInvalid={!!formState.errors.endSubscriptionDate}>
                <FormLabel htmlFor="endSubscriptionDate">{t('OperationEndDate')}</FormLabel>
                <Input id="endSubscriptionDate" type="date" {...register('endSubscriptionDate')} />
                <ErrorMessage error={formState.errors.endSubscriptionDate} />
              </FormControl>
            </Stack>
            <Stack alignItems="flex-start" direction={{ base: 'column', md: 'row' }} spacing="4">
              <Controller
                control={control}
                name="asset"
                render={({ field }): JSX.Element => (
                  <FormControl isInvalid={!!formState.errors?.asset}>
                    <FormLabel htmlFor="asset">{t('ShareType')}</FormLabel>
                    <Select id="asset" menuPlacement="auto" options={options} {...field} />
                    <ErrorMessage error={formState.errors?.asset?.value} />
                  </FormControl>
                )}
              />
              <FormControl isInvalid={!!formState.errors.sharePrice}>
                <FormLabel htmlFor="price-per-share">{t('PricePerShare')}</FormLabel>
                <InputGroup>
                  <Input
                    id="price-per-share"
                    step="0.01"
                    type="number"
                    {...register('sharePrice')}
                  />
                  <InputRightElement color="gray.500">€</InputRightElement>
                </InputGroup>
                <ErrorMessage error={formState.errors.sharePrice} />
              </FormControl>
            </Stack>
            <Stack alignItems="flex-end" direction={{ base: 'column', md: 'row' }} spacing="4">
              <FormControl isInvalid={!!formState.errors.usePivotFiscalAccount}>
                <Checkbox {...register('usePivotFiscalAccount')}>
                  {t('SetupPivotAccount')}
                  <Tooltip hasArrow label={t('PivotAccountSetupCheckboxPrompt')} placement="top">
                    <Icon as={InfoIcon} color="gray.500" ml="2" />
                  </Tooltip>
                </Checkbox>
                <ErrorMessage error={formState.errors.usePivotFiscalAccount} />
              </FormControl>
            </Stack>
            {!isRelatedToOpportunity && (
              <Stack alignItems="flex-end" direction={{ base: 'column', md: 'row' }} spacing="4">
                <FormControl isInvalid={!!formState.errors.isPromiseToSell}>
                  <Checkbox {...register('isPromiseToSell')}>
                    {t('MarketAssociatedToAPromiseToSell')}
                    <Tooltip
                      hasArrow
                      label={t('CheckboxPromptForDesignatedSellers')}
                      placement="top"
                    >
                      <Icon as={InfoIcon} color="gray.500" ml="2" />
                    </Tooltip>
                  </Checkbox>
                  <ErrorMessage error={formState.errors.isPromiseToSell} />
                </FormControl>
              </Stack>
            )}
            <Stack alignItems="flex-end" direction={{ base: 'column', md: 'row' }} spacing="4">
              <FormControl isInvalid={!!formState.errors.enableSellerInvitation}>
                <Checkbox
                  isChecked={isPromiseToSell || enableSellerInvitation}
                  disabled={isPromiseToSell}
                  {...register('enableSellerInvitation')}
                >
                  {t('EnableSellerInvitation')}
                </Checkbox>
                <ErrorMessage error={formState.errors.enableSellerInvitation} />
              </FormControl>
            </Stack>
          </Stack>
        </form>
      </CardBody>
      <CardFooter as={HStack} spacing="4">
        <Button
          isDisabled={isLoading}
          type="button"
          variant="secondary"
          w="full"
          onClick={handleFormCancel}
        >
          {t('Back')}
        </Button>
        <Button form="new-secondary-operation" isLoading={isLoading} type="submit" w="full">
          {t('Next')}
        </Button>
      </CardFooter>
    </Card>
  );
}

export type NewSecondaryOperationInformationsProps = Props;
