import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Badge,
  Button,
  Card,
  CardBody,
  Divider,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerProps,
  HStack,
  Icon,
  Spinner,
  Stack,
  Table,
  Tbody,
  Td,
  Text,
  Tr,
} from '@chakra-ui/react';
import { ArrowNarrowRightIcon } from '@heroicons/react/outline';
import dayjs from 'dayjs';
import { Fragment } from 'react';
import { generatePath, resolvePath, useNavigate } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import { IntlDocumentValues } from '@blockpulse3/data/locales/types';
import { isFeatureEnabled, routes } from '@blockpulse3/data/shared';
import {
  AssetType,
  BalanceInfosFragment,
  Identity,
  IdentityType,
  MovementType,
  OperationDocumentType,
  SubscriptionSide,
  useGenerateCiaUrlMutation,
  useGetActiveSecondaryOperationQuery,
  useGetAssetQuery,
  useGetDocumentPdfUrlLazyQuery,
  useGetMovementsByAssetAndIdentityQuery,
  useGetSubscriptionsByOperationAndIdentityQuery,
} from '@blockpulse3/graphql/hooks';
import { formatNumberCurrency } from '@blockpulse3/helpers';
import {
  AssetBadge,
  DocumentLink,
  ErrorQueryCard,
  IdentityAvatar,
  IdentityCard,
  IdentityCardDescription,
  IdentityCardTitle,
  useErrorToast,
} from '@blockpulse3/ui/commons';
import { SubscriptionPanelDocuments } from '@blockpulse3/web-client/operation/commons';

import { CompanyValuationsCard } from '../CompanyValuationsCard';

type Props = {
  balance: BalanceInfosFragment;
  identityId: Identity['id'] | null;
} & Omit<DrawerProps, 'children'>;

/**
 * StockAssetSidePanel.
 * Display stock asset details. It is also used for the old basic token contract.
 *
 * @param {Props}
 * @returns {JSX.Element}
 */
export function StockAssetSidePanel({ balance, identityId, ...props }: Props): JSX.Element {
  const t = useTranslations();
  const i18nDocumentValue = useTranslations('DocumentValues');

  const navigate = useNavigate();

  const isFeatureSecondary = isFeatureEnabled('secondaryMarket');

  const errorToast = useErrorToast();

  // const [createSaleSubscription] = useCreateSaleSubscriptionMutation();
  const [generateCiaUrl, { loading: ciaLoading }] = useGenerateCiaUrlMutation();
  const [getDocumentPdfUrl] = useGetDocumentPdfUrlLazyQuery();

  // const [isSubscriptionLoading, setIsSubscriptionLoading] = useState<boolean>(false);

  const walletId = balance.walletId;

  const assetReq = useGetAssetQuery({
    variables: {
      companyId: balance.asset.company.id,
      assetId: balance.asset.id,
    },
    skip: !balance.asset.id,
  });

  const movementsReq = useGetMovementsByAssetAndIdentityQuery({
    variables: {
      assetId: balance.asset.id,
      identityId: identityId || '',
    },
    skip: !identityId || !balance.asset.id,
  });

  const activeSecondaryReq = useGetActiveSecondaryOperationQuery({
    variables: {
      assetId: balance.asset.id,
      identityId: identityId || '',
    },
    skip: !isFeatureSecondary || !identityId || !balance.asset.id,
  });
  const activeSecondaryOperation = activeSecondaryReq.data?.getActiveSecondaryOperation;

  const { data: subscriptionsData, loading: subscriptionsLoading } =
    useGetSubscriptionsByOperationAndIdentityQuery({
      variables: {
        identityId: identityId || '',
        operationId: activeSecondaryOperation?.id || '',
        side: SubscriptionSide.SELLER,
      },
      skip: !identityId || !activeSecondaryOperation,
    });
  const existingSubscriptions = subscriptionsData?.getSubscriptionsByOperationAndIdentity;

  if (assetReq.loading || movementsReq.loading) {
    return (
      <Drawer {...props}>
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerBody p="0">
            <Stack alignItems="center" h="full" justifyContent="center" py="4" spacing="4">
              <Spinner />
            </Stack>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    );
  }

  if (!assetReq.data || assetReq.error) {
    return (
      <Drawer size="sm" {...props}>
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerBody pb="16" px="0">
            <ErrorQueryCard height="full" width="full" />
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    );
  }

  const assetDocuments = assetReq.data.asset?.documents || [];
  const movements = movementsReq.data?.getMovementsByAssetAndIdentity || [];

  const handleAssetSale = async (subscriptionId: string): Promise<void> => {
    if (!activeSecondaryOperation || !identityId) return;

    const subscription = existingSubscriptions?.find((sub) => sub.id === subscriptionId);

    // if (!subscriptionId) {
    //   // Create a sale subscription if no subscription exist for the operation
    //   setIsSubscriptionLoading(true);
    //   const { data } = await createSaleSubscription({
    //     variables: {
    //       createSaleSubscriptionInput: {
    //         operationId: activeSecondaryOperation.id,
    //         identityId,
    //       },
    //     },
    //   });
    //   subscription = data?.createSaleSubscription;
    //   setIsSubscriptionLoading(false);
    // }
    if (!subscription?.sellerIdentity) return;

    if (subscription.sellerIdentity.type === IdentityType.INDIVIDUAL) {
      navigate(
        resolvePath(
          generatePath(routes.subscription.href, { subscriptionId: subscription.id }),
          routes.me.href,
        ).pathname,
      );
    } else {
      const companyId = subscription.sellerIdentity.companyIdentity?.id;
      if (!companyId) return;
      navigate(
        resolvePath(
          generatePath(routes.subscription.href, { subscriptionId: subscription.id }),
          generatePath(routes.company.href, { companyId }),
        ).pathname,
      );
    }
  };

  const handleDocumentClick = (documentId: string): void => {
    getDocumentPdfUrl({
      variables: {
        documentId,
      },
      fetchPolicy: 'no-cache',
      onCompleted: ({ getDocumentPdfUrl: pdfUrl }) => {
        window.open(pdfUrl, '_blank');
      },
    });
  };

  const handleCIAClick = (): void => {
    if (!identityId) {
      return;
    }
    generateCiaUrl({
      variables: { companyId: balance.asset.company.id, identityId },
      onCompleted: (data) => {
        window.open(data.generateCiaUrl, '_blank');
      },
      onError: () => {
        errorToast({ title: t('DocumentGenerationError') });
      },
    });
  };

  const { signer, contact } = balance.asset.company;
  const repIndividualIdentity = contact?.individualIdentity || signer.individualIdentity;

  const subscriptionSharePrice =
    movements.length && movements[0]?.subscription?.operation?.sharePrice;

  return (
    <Drawer {...props}>
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerBody p="0">
          <Stack py="4" spacing="6">
            <Card m="4" my="0" p="0" variant="rounded-gray">
              <CompanyValuationsCard
                balance={balance}
                m="0"
                subscriptionSharePrice={subscriptionSharePrice}
                underlyingAssetType={AssetType.ORDINARY_SHARE}
              />
              <CardBody fontSize="sm" px="4" py="1">
                <Accordion allowToggle variant="unstyled">
                  <AccordionItem>
                    <AccordionButton data-cy="expand-asset-details" justifyContent="flex-end">
                      <Text color="gray" fontSize="xs">
                        {t('ShowDetails')}
                      </Text>
                      <AccordionIcon />
                    </AccordionButton>
                    <AccordionPanel as={Stack} px="0" py="3" spacing="2">
                      <HStack alignItems="start" justifyContent="space-between">
                        <Text color="gray.500" fontWeight="500">
                          {t('Asset', { nb: balance.total })}
                        </Text>
                        <HStack>
                          {balance.subscribedAmount > 0 && (
                            <HStack>
                              <AssetBadge
                                type={balance.asset.assetType}
                                value={balance.subscribedAmount}
                              />
                            </HStack>
                          )}
                        </HStack>
                      </HStack>
                      <HStack justifyContent="space-between">
                        <Text color="gray.500" fontWeight="500">
                          {t('InvestedAmount')}
                        </Text>
                        <Text>
                          {typeof balance.investedAmount === 'number'
                            ? formatNumberCurrency(balance.investedAmount)
                            : '-'}
                        </Text>
                      </HStack>
                    </AccordionPanel>
                  </AccordionItem>
                </Accordion>
              </CardBody>
            </Card>
            {isFeatureSecondary && !!activeSecondaryOperation && (
              <Stack px="4" spacing="4">
                <HStack justifyContent="space-between">
                  <Stack alignItems="flex-start" spacing="0">
                    <Text fontSize="md" fontWeight="600">
                      {activeSecondaryOperation.name}
                    </Text>
                    <Badge colorScheme="green">{t('OpenMarket')}</Badge>
                  </Stack>
                  {/* <Button
                    isLoading={isSubscriptionLoading}
                    size="sm"
                    onClick={(): Promise<void> => handleAssetSale()}
                  >
                    {t('SellMyShares')}
                  </Button> */}
                </HStack>
                {!subscriptionsLoading && !!existingSubscriptions?.length && (
                  <Stack spacing="2">
                    <Text color="gray.500" fontSize="sm" fontWeight="500" px="2">
                      {t('SaleInProgress', { nb: existingSubscriptions.length })}
                    </Text>
                    <Table variant="light">
                      <Tbody>
                        {existingSubscriptions.map((subscription) => (
                          <Tr key={subscription.id}>
                            <Td w="full">
                              <HStack alignItems="center" fontWeight="600">
                                <IdentityAvatar
                                  identity={subscription.buyerIdentity || undefined}
                                />
                                <Text>
                                  {subscription.buyerIdentity?.type === IdentityType.COMPANY
                                    ? subscription.buyerIdentity?.companyIdentity?.name
                                    : subscription.buyerIdentity?.individualIdentity?.name}
                                </Text>
                              </HStack>
                            </Td>
                            <Td isNumeric fontWeight="600" whiteSpace="nowrap">
                              {subscription.investAmount === 0
                                ? '-'
                                : formatNumberCurrency(subscription.investAmount)}
                            </Td>
                            <Td>
                              <Button
                                rightIcon={<Icon as={ArrowNarrowRightIcon} boxSize="5" />}
                                size="sm"
                                variant="secondary"
                                onClick={(): Promise<void> => handleAssetSale(subscription.id)}
                              >
                                {t('Resume')}
                              </Button>
                            </Td>
                          </Tr>
                        ))}
                      </Tbody>
                    </Table>
                  </Stack>
                )}
              </Stack>
            )}
            <Divider />
            {movements.map((movement, index) => {
              return (
                <Fragment key={index}>
                  <Stack px="4" spacing="4">
                    <Stack spacing="2">
                      <HStack>
                        <Text fontSize="md" fontWeight="600">
                          {movement.type === MovementType.BURN ||
                          (movement.type === MovementType.TRANSFER &&
                            movement.fromWallet?.id === walletId)
                            ? t('TransferOfShareDate', {
                                date: dayjs(movement.createdAt).format('D MMMM YYYY'),
                              })
                            : t('AcquisitionDateNb', {
                                date: dayjs(movement.createdAt).format('D MMMM YYYY'),
                              })}
                        </Text>
                      </HStack>
                      <HStack fontSize="sm" justifyContent="space-between">
                        <Text color="gray.500" fontWeight="500">
                          {t('SharesCount')}
                        </Text>
                        <Stack alignItems="flex-end" flexShrink="0" spacing="0">
                          <AssetBadge type={movement.asset.assetType} value={movement.amount} />
                        </Stack>
                      </HStack>
                      <HStack fontSize="sm" justifyContent="space-between">
                        <Text color="gray.500" fontWeight="500">
                          {t('AcquisitionPrice')}
                        </Text>
                        <Text fontWeight="500">
                          {t('NbPricePerShare', {
                            price: movement.subscription?.operation?.sharePrice
                              ? formatNumberCurrency(movement.subscription?.operation?.sharePrice)
                              : '-',
                          })}
                        </Text>
                      </HStack>
                    </Stack>
                    {movement.subscription && (
                      <Stack layerStyle="border" py="4" rounded="md">
                        <SubscriptionPanelDocuments subscriptionId={movement.subscription.id} />
                      </Stack>
                    )}
                  </Stack>
                  <Divider />
                </Fragment>
              );
            })}
            <Stack px="4" spacing="2">
              <Text color="gray.600" fontWeight="600">
                {t('CompanyDocuments')}
              </Text>
              <Stack spacing="2">
                {assetDocuments.length &&
                  assetDocuments.map((document) => {
                    if (!document) return null;
                    return (
                      <DocumentLink
                        key={document.id}
                        fileName={
                          document.type === OperationDocumentType.CUSTOM_FILE
                            ? document.title
                            : i18nDocumentValue(document.type as IntlDocumentValues)
                        }
                        onClick={(): void => handleDocumentClick(document.id)}
                      />
                    );
                  })}
                <DocumentLink
                  key={'CIA'}
                  fileName={i18nDocumentValue('CIA')}
                  isLoading={ciaLoading}
                  loadingText={i18nDocumentValue('CIA')}
                  onClick={handleCIAClick}
                />
              </Stack>
            </Stack>
            <Divider />
            <Stack px="4" spacing="2">
              <Text color="gray.600" fontWeight="600">
                {t('Contact', { nb: 1 })}
              </Text>
              <IdentityCard bg="gray.50" p="3">
                <IdentityAvatar type={IdentityType.INDIVIDUAL} />
                <Stack spacing="0">
                  <IdentityCardTitle isChecked>
                    <Text>{repIndividualIdentity?.name}</Text>
                  </IdentityCardTitle>
                  <IdentityCardDescription>
                    <Text>{repIndividualIdentity?.email}</Text>
                  </IdentityCardDescription>
                </Stack>
              </IdentityCard>
            </Stack>
          </Stack>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
}

export type StockAssetSidePanelProps = Props;
