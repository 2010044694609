import {
  Button,
  HStack,
  Icon,
  ResponsiveValue,
  Text,
  UseRadioProps,
  chakra,
  useRadio,
} from '@chakra-ui/react';
import { LibraryIcon } from '@heroicons/react/outline';
import { CheckIcon, PencilIcon, PlusIcon } from '@heroicons/react/solid';
import { useTranslations } from 'use-intl';

import { IntlHoldingMethodTypeValues } from '@blockpulse3/data/locales/types';
import { AssetType, HoldingMethod, noop } from '@blockpulse3/data/shared';

import { AssetBadge } from '../AssetBadge';
import { IWalletWithBalance } from './types';

type Props = {
  wallet?: IWalletWithBalance;
  isReading?: boolean;
  hasIrPme?: boolean;
  assetId?: string;
  onAddWallet?: () => void;
  onUpdateWallet?: () => void;
} & UseRadioProps;

/**
 * HoldingMethodEntry.
 * Simple presentation component, to display holding method informations or to be used as a Radio.
 *
 * @param {Props}
 * @returns {JSX.Element}
 */
export function HoldingMethodEntry({
  wallet,
  isReading = false,
  hasIrPme = false,
  assetId = '',
  onAddWallet = noop,
  onUpdateWallet = noop,
  ...props
}: Props): JSX.Element {
  const t = useTranslations();
  const i18nHoldingMethodType = useTranslations('HoldingMethodTypeValues');

  const { getInputProps, getRadioProps, htmlProps } = useRadio(props);

  const holdingMethod = props.value;
  const shouldDisplayIrPme = holdingMethod === HoldingMethod.DIRECT && hasIrPme;

  // Return holding method if different than default value
  const alternativeHoldingMethod = [HoldingMethod.PEA, HoldingMethod.PEA_PME].includes(
    holdingMethod as HoldingMethod,
  )
    ? holdingMethod
    : undefined;

  const { bankName = '', bankAccountNumber = '', id = '', isComplete = true } = wallet || {};

  const hasWallet = !!id;
  const canWallet = alternativeHoldingMethod && !hasWallet;
  const { balance } = wallet || {};

  let cursor: ResponsiveValue<'pointer' | 'not-allowed' | 'default'> = 'pointer';
  if (isReading) {
    cursor = 'default';
  } else if (props.isDisabled || canWallet || !isComplete) {
    cursor = 'not-allowed';
  }

  return (
    <chakra.label {...htmlProps} cursor={cursor}>
      <input {...getInputProps({})} hidden disabled={canWallet || isReading || !isComplete} />
      <HStack
        background="gray.50"
        boxShadow="base"
        h="50px"
        justifyContent="space-between"
        px="4"
        rounded="md"
        _checked={{
          backgroundColor: 'white',
          borderColor: 'secondary',
          borderWidth: '2px',
        }}
        _disabled={{
          backgroundColor: 'gray.100',
          color: 'gray.500',
        }}
        _hover={{
          backgroundColor: 'gray.100',
        }}
        {...getRadioProps()}
      >
        <HStack spacing="3">
          <Text fontWeight="600" whiteSpace="nowrap">
            {shouldDisplayIrPme
              ? t('IR-PME')
              : i18nHoldingMethodType(holdingMethod as IntlHoldingMethodTypeValues)}
          </Text>
          <HStack spacing="2">
            {bankName && (
              <HStack spacing="1">
                <Icon as={LibraryIcon} boxSize="14px" color="gray.500" />
                <Text color="gray.500" fontSize="sm">
                  {bankName}
                </Text>
              </HStack>
            )}
            <Text fontSize="sm">{bankAccountNumber}</Text>
          </HStack>
        </HStack>
        <HStack justify="right">
          {balance && (
            <AssetBadge
              size="sm"
              type={balance.asset.assetType || AssetType.ORDINARY_SHARE}
              value={balance.total}
              tooltip={
                balance.locked
                  ? t('SharesCommittedToSales', {
                      lockedShares: balance.locked,
                    })
                  : ''
              }
            />
          )}
          <HStack justify="right" minWidth="45px">
            {canWallet && (
              <Button
                color="secondary"
                fontSize="sm"
                iconSpacing="0.5"
                leftIcon={<Icon as={PlusIcon} boxSize="14px" />}
                variant="link"
                w="fit-content"
                onClick={onAddWallet}
              >
                {t('AddInfoAboutHoldingMethod', {
                  holdingMethod: i18nHoldingMethodType(
                    alternativeHoldingMethod as IntlHoldingMethodTypeValues,
                  ),
                })}
              </Button>
            )}
            {hasWallet && !isComplete && (
              <Button
                color="secondary"
                fontSize="sm"
                iconSpacing="0.5"
                leftIcon={<Icon as={PencilIcon} boxSize="14px" />}
                variant="link"
                w="fit-content"
                onClick={onUpdateWallet}
              >
                {balance ? t('CompleteAction') : t('CompleteHoldingMethod')}
              </Button>
            )}
            {((hasWallet && isComplete) || props.isChecked) && (
              <HStack alignItems="baseline">
                {hasWallet && isComplete && wallet?.holdingMethod !== HoldingMethod.DIRECT && (
                  <Button boxSize="18px" minW="auto" variant="unstyled" onClick={onUpdateWallet}>
                    <Icon as={PencilIcon} boxSize="18px" color="gray.500" float="right" />
                  </Button>
                )}
                {props.isChecked && <Icon as={CheckIcon} boxSize="18px" />}
              </HStack>
            )}
          </HStack>
        </HStack>
      </HStack>
    </chakra.label>
  );
}
