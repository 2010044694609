import {
  Badge,
  Button,
  Divider,
  Flex,
  HStack,
  Icon,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
} from '@chakra-ui/react';
import { PlusIcon } from '@heroicons/react/outline';
import { ShieldExclamationIcon } from '@heroicons/react/solid';
import { useEffect } from 'react';
import { generatePath, resolvePath, useMatch, useNavigate, useParams } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import { routes } from '@blockpulse3/data/shared';
import {
  CompanyEntityInfosFragment,
  CompanyStatus,
  SpvStatus,
  useGetCompaniesQuery,
  useGetControlledCompaniesQuery,
  useGetPartialCompaniesQuery,
} from '@blockpulse3/graphql/hooks';
import {
  ResponsiveModal,
  ResponsiveModalFooter,
  ResponsiveModalProps,
} from '@blockpulse3/ui/commons';
import { IBadge, useBadge } from '@blockpulse3/ui/ui-hooks';
import { useAuthUser, useManagedIndividual } from '@blockpulse3/web-client/auth';

import { SwitcherItem } from './SwitcherItem';
import { useWorkspaceSwitcher } from './provider';

type Props = Omit<ResponsiveModalProps, 'children' | 'isOpen' | 'onClose'>;

/**
 * SwitcherModal.
 * Select another workspace or create a new one.
 *
 * @param {Props}
 * @returns {JSX.Element}
 */
export function SwitcherModal({ ...props }: Props): JSX.Element | null {
  const t = useTranslations();

  const navigate = useNavigate();
  const isManaging = useMatch(routes.manage.href + '/*');

  /* ** Current workspace ** */
  const { user: authUser, spaceName } = useAuthUser();
  const { individual } = useManagedIndividual();
  const { companyId = '' } = useParams();

  /* ** Get items ** */
  const companiesReq = useGetCompaniesQuery({
    variables: {
      individualIdentityId: individual?.id,
    },
    skip: !individual?.id,
  });
  const runningCompanies = companiesReq.data?.companies || [];

  const partialsReq = useGetPartialCompaniesQuery({
    variables: {
      individualIdentityId: individual?.id,
    },
    skip: !individual?.id,
  });
  const partialCompanies = partialsReq.data?.partials || [];

  const controlledReq = useGetControlledCompaniesQuery();
  const controlledCompanies =
    controlledReq.data?.getControlledCompanies.map((co) => {
      const newCo = { ...co, isControlled: true };
      return newCo;
    }) || [];

  const allCompanies: (CompanyEntityInfosFragment & { isControlled?: boolean })[] = [
    ...runningCompanies,
    ...partialCompanies,
    ...controlledCompanies,
  ];
  if (allCompanies.length) {
    allCompanies.sort((a, b) => a.name.localeCompare(b.name));
  }

  /* ** Get workspace switcher display status and method ** */
  const { isSwitcherOpen, onSwitcherClose } = useWorkspaceSwitcher();

  /* ** Dynamic badge getter ** */
  const { getBadge } = useBadge(
    null,
    [
      {
        value: SpvStatus.VALIDATED,
        color: 'yellow',
        label: t('InCreation'),
      },
      {
        value: SpvStatus.SIGNED,
        color: 'yellow',
        label: t('InCreation'),
      },
      {
        value: SpvStatus.VERIFIED,
        color: 'yellow',
        label: t('InCreation'),
      },
      {
        value: SpvStatus.CREATING,
        color: 'blue',
        label: t('RegistrationInProgress'),
      },
    ],
    { color: 'gray', label: t('Draft', { nb: 1 }) },
  );

  /* ** Redirect to clicked workspace dashboard ** */
  const handleWorkspaceClick = (
    selectedCompany: CompanyEntityInfosFragment & { isControlled?: boolean },
  ): void => {
    if (selectedCompany) {
      let relPath = generatePath(routes.manage.company.href, { companyId: selectedCompany.id });
      if (selectedCompany.isControlled) {
        relPath = generatePath(routes.manage.company.full, { companyId: selectedCompany.id });
      } else if (isManaging && individual?.id) {
        relPath = resolvePath(
          relPath,
          generatePath(routes.manage.individual.full, { individualIdentityId: individual?.id }),
        ).pathname;
      }
      navigate(relPath);
    }
    onSwitcherClose();
  };

  /* ** Callback on user workspace click ** */
  const handleUserWorkspaceClick = (): void => {
    navigate(
      isManaging && individual?.id && individual?.id !== authUser?.individualIdentity?.id
        ? generatePath(routes.manage.individual.full, { individualIdentityId: individual?.id })
        : routes.me.href,
    );
    onSwitcherClose();
  };

  const handleAddExistingCompany = (): void => {
    navigate(routes.onboardings.company.href);
    onSwitcherClose();
  };

  const handleAddSPV = (): void => {
    navigate(routes.onboardings.spv.href);
    onSwitcherClose();
  };

  useEffect(() => {
    if (isSwitcherOpen) {
      companiesReq.refetch();
      partialsReq.refetch();
      controlledReq.refetch();
    }
  }, [isSwitcherOpen]);

  if (!isSwitcherOpen) return null;

  return (
    <ResponsiveModal
      isCentered
      isOpen={isSwitcherOpen}
      size="xl"
      onClose={onSwitcherClose}
      {...props}
    >
      <ModalOverlay />
      <ModalContent data-cy="switcher-modal">
        <ModalHeader>{t('SwitchWorkspace')}</ModalHeader>
        <ModalCloseButton />
        <ModalBody maxH="500" p="0">
          <Stack spacing="0" w="full">
            {individual && (
              <SwitcherItem
                key={individual?.id}
                isActive={!companyId}
                isUser={true}
                name={`${individual?.firstName} ${individual?.lastName}`}
                profilePicture={individual?.identity?.profilePicture}
                workspaceId={individual?.id}
                onClick={handleUserWorkspaceClick}
              >
                <Text fontSize="sm" fontWeight="light" variant="ellipsis">
                  {t('PersonalSpace')}
                </Text>
              </SwitcherItem>
            )}
            {allCompanies.map((company) => {
              // Badge for draft companies
              let badge: IBadge | undefined;
              if (company.status !== CompanyStatus.RUNNING) {
                const status = company?.spvStatus || SpvStatus.EDITING;
                badge = getBadge(status);
              }
              return (
                <SwitcherItem
                  key={company.id}
                  isActive={company.id === companyId}
                  isUser={false}
                  profilePicture={company.identity?.profilePicture}
                  workspaceId={company.id}
                  name={
                    company.name ||
                    t('NewCompanyID', {
                      id: company.id.toString(),
                    })
                  }
                  rightInfo={
                    company.isControlled && (
                      <HStack fontSize="sm" spacing="1">
                        <Icon as={ShieldExclamationIcon} boxSize="14px" />
                        <Text>{spaceName}</Text>
                      </HStack>
                    )
                  }
                  onClick={(): void => handleWorkspaceClick(company)}
                >
                  <HStack>
                    {company.registrationNumber && (
                      <Text fontSize="sm" fontWeight="light" variant="ellipsis">
                        {company.registrationNumber}
                      </Text>
                    )}
                    {badge && <Badge colorScheme={badge.color}>{badge.label}</Badge>}
                  </HStack>
                </SwitcherItem>
              );
            })}
          </Stack>
        </ModalBody>
        <Divider />
        <ResponsiveModalFooter p="4">
          <Stack direction={['column', 'row']} spacing="5" w="full">
            <Button
              data-cy="add-existing-company"
              variant="container"
              onClick={handleAddExistingCompany}
            >
              <HStack alignItems="flex-start">
                <Flex
                  alignItems="center"
                  bg="gray.100"
                  boxSize="32px"
                  flexShrink="0"
                  justifyContent="center"
                  mr="2"
                  rounded="sm"
                >
                  <Icon as={PlusIcon} boxSize="18px" />
                </Flex>
                <Text>{t('ExistingCompanyImportAction')}</Text>
              </HStack>
            </Button>
            <Button variant="container" onClick={handleAddSPV}>
              <HStack alignItems="flex-start" data-cy="create-spv-company">
                <Flex
                  alignItems="center"
                  bg="gray.100"
                  boxSize="32px"
                  flexShrink="0"
                  justifyContent="center"
                  mr="2"
                  rounded="sm"
                >
                  <Icon as={PlusIcon} boxSize="18px" />
                </Flex>
                <Text>{t('CreateSPVLong')}</Text>
              </HStack>
            </Button>
          </Stack>
        </ResponsiveModalFooter>
      </ModalContent>
    </ResponsiveModal>
  );
}

export type SwitcherModalProps = Props;
