import { ChevronDownIcon } from '@chakra-ui/icons';
import {
  Button,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Stack,
  useDisclosure,
} from '@chakra-ui/react';
import {
  SearchIcon,
  UploadIcon,
  UserAddIcon,
  UserGroupIcon,
  UserRemoveIcon,
} from '@heroicons/react/outline';
import { useRef } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import {
  useCreateSubscriptionsFromOpportunityMutation,
  useDeleteAllSubscriptionsMutation,
  useGetOperationQuery,
  useGetSubscriptionsQuery,
} from '@blockpulse3/graphql/hooks';
import { DeleteConfirmModal, useErrorToast, useSuccessToast } from '@blockpulse3/ui/commons';

type Props = {
  /* ** Callback for the search input field ** */
  onSearchInputChange: (input: string) => void;
  /* ** Callback on new subscription ** */
  onCreateSubscription: () => void;
  /* ** Callback on the CSV file import ** */
  onImportSubscriptions: () => void;
};

/**
 * SubscribersTableControls.
 * Controls section of the `<SubscribersTable />`. Add subscriber and import CSV.
 *
 * @param {Props}
 * @returns {JSX.Element}
 */
export function SubscribersTableControls({
  onSearchInputChange,
  onCreateSubscription,
  onImportSubscriptions,
}: Props): JSX.Element {
  const t = useTranslations();

  const { operationId = '' } = useParams();

  const confirmRef = useRef(null);

  const errorToast = useErrorToast();
  const successToast = useSuccessToast();

  const deleteModal = useDisclosure();

  const operationReq = useGetOperationQuery({
    variables: { operationId },
    skip: !operationId,
  });
  const operation = operationReq.data?.operation;
  const opportunityId = operation?.opportunityId;

  const subscriptionsReq = useGetSubscriptionsQuery({
    variables: { operationId, first: 150 },
  });

  const [deleteAllSubscriptions, { loading: deleteAllSubsLoading }] =
    useDeleteAllSubscriptionsMutation();

  const [createSubscriptionsFromOpportunity, { loading: loadingCreateSubs }] =
    useCreateSubscriptionsFromOpportunityMutation();

  const handleSearchInputChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    onSearchInputChange(event.target.value);
  };

  const handleCreateSubscription = (): void => {
    onCreateSubscription();
  };

  const handleImportSubscriptions = (): void => {
    onImportSubscriptions();
  };

  const handleCreateSubscriptionsFromOpportunity = (): void => {
    if (!operationId) return;

    createSubscriptionsFromOpportunity({
      variables: { operationId },
      onCompleted: () => subscriptionsReq.refetch(),
      onError: () => errorToast({ title: t('ErrorAddingBuyers') }),
    });
  };

  const handleDeleteAllSubs = async (): Promise<void> => {
    const refetchAndClose = async (): Promise<void> => {
      await Promise.all([subscriptionsReq.refetch(), operationReq.refetch()]);
      deleteModal.onClose();
    };

    await deleteAllSubscriptions({
      variables: {
        operationId,
      },
      onCompleted: () => {
        successToast({ title: t('DeleteAllSubscribersSuccess') });
        refetchAndClose();
      },
      onError: () => {
        errorToast({ title: t('DeleteAllSubscribersError') });
        refetchAndClose();
      },
    });
  };

  const addBuyersDisabled = !operation || (!!opportunityId && operation.subscriptionCount > 0);

  const hasAddedSubscriptions = subscriptionsReq.data?.subscriptions?.edges.length;

  const isLoading = deleteAllSubsLoading || subscriptionsReq.loading || operationReq.loading;

  return (
    <Stack>
      <Heading size="md">{t('SubscribersList')}</Heading>
      <Stack direction={{ base: 'column', md: 'row' }} justifyContent="space-between" spacing="4">
        <InputGroup w={{ base: 'full', md: '300px' }}>
          <InputLeftElement pointerEvents="none">
            <Icon as={SearchIcon} boxSize="18px" />
          </InputLeftElement>
          <Input placeholder={t('Research')} onChange={handleSearchInputChange} />
        </InputGroup>
        <Stack direction={{ base: 'column', md: 'row' }} justifyContent="space-between">
          <Button
            data-cy="add-subscriber"
            leftIcon={<Icon as={UserAddIcon} boxSize="24px" />}
            width={{ base: 'fit-content' }}
            onClick={handleCreateSubscription}
          >
            {t('AddSubscriber')}
          </Button>
          <Menu>
            <MenuButton
              as={Button}
              isDisabled={isLoading}
              rightIcon={<ChevronDownIcon />}
              variant="secondary"
            >
              {t('Action', { nb: 2 })}
            </MenuButton>
            <MenuList>
              <MenuItem
                fontWeight="600"
                icon={<Icon as={UploadIcon} boxSize="5" />}
                onClick={handleImportSubscriptions}
              >
                {t('CSVImport')}
              </MenuItem>
              {opportunityId && (
                <MenuItem
                  fontWeight="600"
                  icon={<Icon as={UserGroupIcon} boxSize="5" />}
                  isDisabled={operationReq.loading || loadingCreateSubs || addBuyersDisabled}
                  onClick={handleCreateSubscriptionsFromOpportunity}
                >
                  {t('InviteAllInterestedBuyers')}
                </MenuItem>
              )}
              <MenuItem
                fontWeight="600"
                icon={<Icon as={UserRemoveIcon} boxSize="5" />}
                isDisabled={!hasAddedSubscriptions}
                onClick={deleteModal.onOpen}
              >
                {t('DeleteAllSubscribers')}
              </MenuItem>
            </MenuList>
          </Menu>
        </Stack>
      </Stack>
      <DeleteConfirmModal
        isLoading={deleteAllSubsLoading}
        isOpen={deleteModal.isOpen}
        leastDestructiveRef={confirmRef}
        subtitle={t('DefinitiveAction')}
        title={t('DeleteSubscriberQuestion', { nb: 2 })}
        onClose={deleteModal.onClose}
        onDelete={handleDeleteAllSubs}
      />
    </Stack>
  );
}

export type SubscribersTableControlsProps = Props;
