import {
  Badge,
  Button,
  HStack,
  Icon,
  IconButton,
  Input,
  Skeleton,
  Stack,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { PlusIcon, TrashIcon } from '@heroicons/react/outline';
import axios from 'axios';
import { useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import {
  DocumentStatus,
  MemberRole,
  OperationDocumentType,
  useDeleteSubscriptionCustomDocumentMutation,
  useGetDocumentPdfUrlLazyQuery,
  useGetSubscriptionQuery,
} from '@blockpulse3/graphql/hooks';
import { getDocumentIds } from '@blockpulse3/helpers';
import {
  DeleteConfirmModal,
  DocumentLink,
  useErrorToast,
  useSuccessToast,
} from '@blockpulse3/ui/commons';
import { useBadge } from '@blockpulse3/ui/ui-hooks';
import { useManagerRole } from '@blockpulse3/web-client/auth';

/**
 * IntentPanelDocuments.
 * Document logic section of the intent side panel.
 *
 * @returns {JSX.Element}
 */
export function IntentPanelDocuments(): JSX.Element {
  const t = useTranslations();
  const i18nDocumentTitle = useTranslations('DocumentValues');

  const inputRef = useRef<HTMLInputElement>(null);
  const deleteRef = useRef(null);

  const deleteModal = useDisclosure();

  const errorToast = useErrorToast();
  const successToast = useSuccessToast();

  const { subscriptionId = '' } = useParams();
  const isUserAuthorized = useManagerRole({ subscriptionId }, [
    MemberRole.OWNER,
    MemberRole.ADMIN,
    MemberRole.COMPLIANCE_OFFICER,
  ]);

  const { data, loading, error, refetch } = useGetSubscriptionQuery({
    variables: { subscriptionId },
    skip: !subscriptionId,
  });

  const [selectedFileId, setSelectedFileId] = useState<string>('');
  const [isUploadLoading, setIsUploadLoading] = useState<boolean>(false);

  /* ** Query to get pdf url of a document ** */
  const [getDocumentPdfUrl] = useGetDocumentPdfUrlLazyQuery();
  const [deleteCustomDocument, { loading: isCustomDocumentDeletionLoading }] =
    useDeleteSubscriptionCustomDocumentMutation();

  const subscription = data?.subscription;
  const subscriptionDocuments = subscription?.subscriptionDocuments || [];
  const termSheetDocument = subscriptionDocuments.find(
    (doc) => doc.type === OperationDocumentType.TERM_SHEET,
  );
  const fundsSourceCertificateFiles = subscriptionDocuments.filter(
    (doc) => doc.type === OperationDocumentType.FUNDS_SOURCE_CERTIFICATE,
  );
  const customFiles = subscriptionDocuments.filter(
    (doc) => doc.type === OperationDocumentType.CUSTOM_FILE,
  );

  const documents = getDocumentIds(subscriptionDocuments);

  const termSheetDocumentStatus = useBadge(
    termSheetDocument?.status,
    [
      { value: DocumentStatus.SIGNED, color: 'green', label: t('Signed') },
      { value: DocumentStatus.ONGOING, color: 'gray', label: t('RequiredAction', { nb: 1 }) },
      {
        value: DocumentStatus.CANCELLED,
        color: 'red',
        label: t('Canceled'),
      },
      {
        value: DocumentStatus.SIGNATURES_PENDING,
        color: 'yellow',
        label: t('InProgress'),
      },
    ],
    { color: 'gray', label: t('RequiredAction', { nb: 1 }) },
  );

  if (loading || !data || error) {
    return (
      <Stack px="4" spacing="2">
        <Text color="gray.600" fontWeight="600">
          {t('Document', { nb: 2 })}
        </Text>
        <Skeleton h="60px" />
      </Stack>
    );
  }

  const handleDocumentClick = (documentId: string): void => {
    getDocumentPdfUrl({
      variables: {
        documentId,
      },
      fetchPolicy: 'no-cache',
      onCompleted: ({ getDocumentPdfUrl: pdfUrl }) => {
        window.open(pdfUrl, '_blank');
      },
    });
  };

  const handleAddDocumentClick = (): void => {
    inputRef?.current?.click();
  };

  const handleFileInputChange = async (e: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
    const files = e.target.files;

    if (!files?.[0]) return;

    const formData = new FormData();
    formData.append('subscriptionDocument', files[0]);
    formData.append('subscriptionId', subscriptionId);
    formData.append('documentType', OperationDocumentType.CUSTOM_FILE);

    setIsUploadLoading(true);
    await axios
      .post(
        process.env['NX_API_CONTROLLER_ENDPOINT'] + '/subscriptions/uploadSubscriptionDocument',
        formData,
        {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'multipart/form-data',
          },
        },
      )
      .then(() => {
        successToast({ title: t('SuccessfulFileUpload') });
      })
      .catch(() => {
        errorToast({ title: t('FileUploadError') });
      });

    await refetch();
    setIsUploadLoading(false);
  };

  const handleFileDelete = (fileId: string): void => {
    setSelectedFileId(fileId);
    deleteModal.onOpen();
  };

  const handleDelete = (): void => {
    if (!selectedFileId) return;

    deleteCustomDocument({
      variables: {
        subscriptionId,
        documentId: selectedFileId,
      },
      onCompleted: () => {
        refetch();
      },
    });

    deleteModal.onClose();
  };

  return (
    <Stack px="4" spacing="2">
      <HStack justifyContent="space-between">
        <Text color="gray.600" fontWeight="600">
          {t('Document', { nb: subscriptionDocuments.length })}
        </Text>
        <form id="add-document-form">
          <Input
            accept=".pdf"
            display="none"
            ref={inputRef}
            type="file"
            onChange={handleFileInputChange}
          />
          <Button
            alignSelf="flex-start"
            disabled={isUploadLoading}
            isLoading={isUploadLoading}
            leftIcon={<Icon as={PlusIcon} />}
            loadingText={t('Loading')}
            size="sm"
            variant="secondary"
            onClick={handleAddDocumentClick}
          >
            {t('AddAnnexDocument')}
          </Button>
        </form>
      </HStack>
      <Stack spacing="2">
        {!documents[OperationDocumentType.TERM_SHEET] && !customFiles.length && (
          <Text color="gray.500" fontSize="sm">
            {t('NoDocumentsLinked')}
          </Text>
        )}
        {documents[OperationDocumentType.TERM_SHEET] && (
          <DocumentLink
            fileName={i18nDocumentTitle(OperationDocumentType.TERM_SHEET)}
            onClick={(): void => handleDocumentClick(documents[OperationDocumentType.TERM_SHEET])}
          >
            <Badge colorScheme={termSheetDocumentStatus.badge.color} fontSize="xs">
              {termSheetDocumentStatus.badge.label}
            </Badge>
          </DocumentLink>
        )}
        {fundsSourceCertificateFiles.map((doc, index) => (
          <DocumentLink
            key={doc.id}
            fileName={
              fundsSourceCertificateFiles.length > 1
                ? i18nDocumentTitle('FUNDS_SOURCE_CERTIFICATE') + ' n° ' + (index + 1)
                : i18nDocumentTitle('FUNDS_SOURCE_CERTIFICATE')
            }
            onClick={(): void => handleDocumentClick(doc.id)}
          />
        ))}
        {customFiles.map((file, index) => {
          const fileName =
            file.title === 'CUSTOM_FILE' ? `${t('Annex', { nb: 1 })} ${++index}` : file.title;
          return (
            <HStack key={file.id} justify="space-between">
              <DocumentLink
                fileName={fileName}
                onClick={(): void => handleDocumentClick(file.id)}
              />
              {file.parentDocumentType === 'SubscriptionDocument' && isUserAuthorized && (
                <IconButton
                  aria-label="delete-document"
                  icon={<Icon as={TrashIcon} boxSize="4" />}
                  isDisabled={isCustomDocumentDeletionLoading}
                  size="xs"
                  variant="icon-delete"
                  onClick={(): void => handleFileDelete(file.id)}
                />
              )}
            </HStack>
          );
        })}
      </Stack>
      <DeleteConfirmModal
        isLoading={isCustomDocumentDeletionLoading}
        isOpen={deleteModal.isOpen}
        leastDestructiveRef={deleteRef}
        subtitle={t('DefinitiveAction')}
        title={t('DeleteDocumentQuestion')}
        onClose={deleteModal.onClose}
        onDelete={handleDelete}
      />
    </Stack>
  );
}
