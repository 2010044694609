import { Badge, Button, HStack, Icon, Stack, Td, Text, Tr, useDisclosure } from '@chakra-ui/react';
import {
  ArchiveIcon,
  ArrowNarrowRightIcon,
  ClockIcon,
  ShieldCheckIcon,
  TableIcon,
  UploadIcon,
  UserGroupIcon,
} from '@heroicons/react/outline';
import axios from 'axios';
import { useState } from 'react';
import { generatePath, resolvePath, useMatch, useNavigate, useParams } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import { noop, routes } from '@blockpulse3/data/shared';
import {
  AssetType,
  FundraisingType,
  FundraisingWorkflowType,
  Operation,
  OperationStatus,
} from '@blockpulse3/graphql/hooks';
import { downloadCsv, downloadFile, formatDate, formatNumberCurrency } from '@blockpulse3/helpers';
import { IconButtonWithTooltip, useErrorToast } from '@blockpulse3/ui/commons';
import { useBadge } from '@blockpulse3/ui/ui-hooks';

import { OperationRow } from '../types';
import { ExtendOperationModal } from './ExtendOperationModal';
import { UploadSubscriptionTemplateModal } from './UploadSubscriptionTemplateModal';

type Props = {
  row: OperationRow;
};

export function OperationTableFundraisingRow({ row }: Props): JSX.Element {
  const t = useTranslations();

  const [isPECLoading, setIsPECLoading] = useState<boolean>(false);
  const [isArchiveLoading, setIsArchiveLoading] = useState<boolean>(false);
  const [isSignedCertificatesLoading, setIsSignedCertificatesLoading] = useState<boolean>(false);

  const { companyId = '' } = useParams();

  const errorToast = useErrorToast();

  const navigate = useNavigate();

  const extendFundraisingModal = useDisclosure();
  const uploadSubscriptionTemplateModal = useDisclosure();

  const isSpace = useMatch(routes.space.href + '/*');

  const isAllInSubscriptionWorkflow =
    row.fundraising?.workflowType === FundraisingWorkflowType.ALL_IN;

  /* ** Fundraising status badge ** */
  const { badge } = useBadge(
    row.status,
    [
      {
        value: OperationStatus.CLOSED,
        label: t(isAllInSubscriptionWorkflow ? 'ClosedAllInF' : 'ClosedF'),
        color: 'yellow',
      },
      {
        value: OperationStatus.STARTED,
        label: t('InProgress'),
        color: 'yellow',
      },
      {
        value: OperationStatus.REVIEWED,
        label: t('RevisedF'),
        color: 'blue',
      },
      {
        value: OperationStatus.FINALIZED,
        label: t('FinalizedF'),
        color: 'green',
      },
    ],
    { label: t('Draft', { nb: 1 }), color: 'gray' },
  );

  const handleOperationDraftClick = (): void => {
    if (isSpace || row.status !== OperationStatus.DRAFT) {
      return handleOperationCurrentClick();
    }

    if (!companyId) {
      return;
    }

    navigate(
      generatePath(
        row.fundraising?.type === FundraisingType.PRIVATE
          ? routes.company.newFundraising.private.href +
              '/' +
              routes.company.newFundraising.private.edit.href
          : routes.company.newFundraising.crowdfunding.href +
              '/' +
              routes.company.newFundraising.crowdfunding.edit.href,
        {
          operationId: row.id,
        },
      ),
    );
  };

  const handleOperationCurrentClick = (): void => {
    const rowCompanyId = isSpace && row.company?.id ? row.company.id : companyId;
    if (rowCompanyId) {
      const relPath = generatePath(routes.company.fundraising.href, {
        companyId: rowCompanyId,
        operationId: row.id,
      });
      navigate(
        isSpace
          ? resolvePath(
              relPath,
              generatePath(routes.space.company.full, { companyId: rowCompanyId }),
            ).pathname
          : relPath,
      );
    }

    return;
  };

  const handleRowClick = (): void => {
    switch (row.status) {
      case OperationStatus.DRAFT: {
        handleOperationDraftClick();
        break;
      }

      default: {
        handleOperationCurrentClick();
        break;
      }
    }
  };

  const handlePECDownload = (operationId: Operation['id']): void => {
    setIsPECLoading(true);
    axios
      .post(
        process.env['NX_API_CONTROLLER_ENDPOINT'] + '/operations/exportRegulatoryReportCsv',
        { operationId },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
            Accept: 'text/csv',
          },
          responseType: 'text',
        },
      )
      .then((response) => {
        downloadCsv(response.data, `pec-${operationId}.csv`);
        setIsPECLoading(false);
      })
      .catch(() => {
        setIsPECLoading(false);
      });
  };

  const handleArchiveDownload = (operationId: Operation['id']): void => {
    setIsArchiveLoading(true);
    axios
      .post(
        process.env['NX_API_CONTROLLER_ENDPOINT'] + '/operations/exportSubscriptionsReportZip',
        {
          operationId,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
            Accept: 'application/zip',
          },
          responseType: 'blob',
        },
      )
      .then((response) => {
        downloadFile(response.data, `archive-files-${operationId}.zip`);
        setIsArchiveLoading(false);
      })
      .catch(() => {
        setIsArchiveLoading(false);
      });
  };

  const handleSignedCertificatesDownload = (operationId: Operation['id']): void => {
    setIsSignedCertificatesLoading(true);
    axios
      .post(
        process.env['NX_API_CONTROLLER_ENDPOINT'] + '/operations/exportSignedCertificateDocuments',
        { operationId },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
            Accept: 'application/zip',
          },
          responseType: 'blob',
        },
      )
      .then((response) => {
        downloadFile(response.data, `signed-certificates-${operationId}.zip`);
        setIsSignedCertificatesLoading(false);
      })
      .catch(() => {
        errorToast({ title: t('ArchiveDownloadFailed') });
        setIsSignedCertificatesLoading(false);
      });
  };
  const endDate = row.closingDate || row.endSubscriptionDate;

  const canExportPEC = isSpace && row.status !== OperationStatus.DRAFT;
  const isClosed =
    isSpace &&
    [OperationStatus.CLOSED, OperationStatus.REVIEWED, OperationStatus.FINALIZED].includes(
      row.status,
    );
  const canExportArchive = isClosed;
  const canUploadSubscriptionTemplate = !isClosed;
  const canExportSignedCertificates = isSpace && row.status === OperationStatus.FINALIZED;
  const canExtendOperation = isSpace && row.status === OperationStatus.STARTED && endDate;

  const operationSubTitle =
    row.assetType === AssetType.BOND ? t('BondIssuance') : t('CapitalIncrease');

  return (
    <Tr role="button" onClick={!isSpace ? handleRowClick : noop}>
      <Td role="button" onClick={isSpace ? handleRowClick : noop}>
        <Text fontWeight="600">{row.name}</Text>
        <Text color="gray.500" fontSize="xs" fontWeight="400">
          {operationSubTitle}
        </Text>
      </Td>
      {isSpace && (
        <>
          <Td>
            <HStack spacing="1">
              <IconButtonWithTooltip
                aria-label="extend operation"
                icon={<Icon as={ClockIcon} boxSize="18px" color="gray.700" />}
                isDisabled={!canExtendOperation}
                label={t('ExtendOperation')}
                variant="secondary"
                onClick={extendFundraisingModal.onOpen}
              />
              <IconButtonWithTooltip
                aria-label="import subscription template"
                icon={<Icon as={UploadIcon} boxSize="18px" color="gray.700" />}
                isDisabled={!canUploadSubscriptionTemplate}
                label={t('ImportSubscriptionTemplate')}
                variant="secondary"
                onClick={uploadSubscriptionTemplateModal.onOpen}
              />
              <IconButtonWithTooltip
                aria-label="Download archive of signed certificates"
                icon={<Icon as={ShieldCheckIcon} boxSize="18px" color="gray.700" />}
                isDisabled={!canExportSignedCertificates || isSignedCertificatesLoading}
                isLoading={isSignedCertificatesLoading}
                label={t('DownloadSignedCertificates')}
                variant="secondary"
                onClick={(): void => handleSignedCertificatesDownload(row.id)}
              />
              <IconButtonWithTooltip
                aria-label="Download PEC file"
                icon={<Icon as={TableIcon} boxSize="18px" color="gray.700" />}
                isDisabled={!canExportPEC || isPECLoading}
                isLoading={isPECLoading}
                label={t('DownloadPECFile')}
                variant="secondary"
                onClick={(): void => handlePECDownload(row.id)}
              />
              <IconButtonWithTooltip
                aria-label="Download archive of operation file"
                icon={<Icon as={ArchiveIcon} boxSize="18px" color="gray.700" />}
                isDisabled={!canExportArchive || isArchiveLoading}
                isLoading={isArchiveLoading}
                label={t('DownloadOperationArchive')}
                variant="secondary"
                onClick={(): void => handleArchiveDownload(row.id)}
              />
              {canExtendOperation && extendFundraisingModal.isOpen && (
                <ExtendOperationModal
                  isOpen={extendFundraisingModal.isOpen}
                  operationId={row.id}
                  operationType={row.type}
                  onClose={extendFundraisingModal.onClose}
                />
              )}
              {canUploadSubscriptionTemplate && uploadSubscriptionTemplateModal.isOpen && (
                <UploadSubscriptionTemplateModal
                  isOpen={uploadSubscriptionTemplateModal.isOpen}
                  operationId={row.id}
                  onClose={uploadSubscriptionTemplateModal.onClose}
                />
              )}
            </HStack>
          </Td>
          <Td>
            <Badge colorScheme={badge.color}>{badge.label}</Badge>
          </Td>
          <Td>
            <Text fontWeight="600">{row.company?.name || '-'}</Text>
          </Td>
        </>
      )}
      <Td isNumeric fontWeight="600">
        {row.fundraising?.hardCap ? formatNumberCurrency(row?.fundraising?.hardCap) : '-'}
      </Td>
      <Td fontWeight="600">
        <Stack alignItems="center" direction="row">
          <Icon as={UserGroupIcon} boxSize="18px" color="gray.900" />
          <Text>{row.subscriptionCount}</Text>
        </Stack>
      </Td>
      <Td fontWeight="600">{endDate ? formatDate(endDate, 'll') : '-'}</Td>
      <Td textAlign="right">
        <Button
          rightIcon={<Icon as={ArrowNarrowRightIcon} boxSize="5" />}
          variant="secondary"
          onClick={isSpace ? handleRowClick : noop}
        >
          {t('ShowDetails')}
        </Button>
      </Td>
    </Tr>
  );
}

export type OperationTableFundraisingRowProps = Props;
