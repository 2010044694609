import { Button, HStack, Stack, Text } from '@chakra-ui/react';

import { noop } from '@blockpulse3/data/shared';
import { CompanyIdentityAvatar, UserIdentityAvatar } from '@blockpulse3/ui/commons';

type Props = {
  /* ** Workspace ID ** */
  workspaceId: string;
  /* ** Is user workspace ** */
  isUser: boolean;
  /* Is active workspace ** */
  isActive?: boolean;
  /* ** Item name ** */
  name: string;
  /* ** Profile picture url ** */
  profilePicture?: string | null;
  /* ** Item info displayed on right ** */
  rightInfo?: React.ReactNode;
  /* ** Item subtitle ** */
  children: React.ReactNode;
  /* ** Callback on click ** */
  onClick?: (value: string) => void;
};

/**
 * SwitcherItem.
 * Component rendering moral or natural version of a workspace in Switcher list.
 *
 * @param {Props}
 * @returns {JSX.Element}
 */
export function SwitcherItem({
  workspaceId,
  isUser,
  isActive,
  name,
  profilePicture,
  rightInfo,
  children,
  onClick = noop,
}: Props): JSX.Element {
  /* ** Handle item click, pass value ** */
  const handleItemClick = (): void => {
    onClick(workspaceId);
  };

  return (
    <Button isActive={isActive} variant="item" onClick={handleItemClick}>
      <HStack justifyContent="space-between" w="full">
        <HStack spacing="3">
          {isUser ? (
            <UserIdentityAvatar boxSize="12" src={profilePicture} />
          ) : (
            <CompanyIdentityAvatar boxSize="12" src={profilePicture} />
          )}
          <Stack alignItems="flex-start" spacing="1" title={name}>
            <Text fontSize="md" noOfLines={1} title={name} wordBreak="break-all">
              {name}
            </Text>
            {children}
          </Stack>
        </HStack>
        {rightInfo || null}
      </HStack>
    </Button>
  );
}
