export const routes = {
  closePopup: { href: '/close-popup' },
  login: {
    href: '/login',
    withEmail: {
      href: '/login?email=:email',
    },
  },
  autoLogin: { href: '/auto-login' },
  signup: { href: '/signup' },
  invitationSignUp: { href: '/invite', full: '/invite?email=:email&token=:token' },
  me: {
    href: '/me',
    documents: { href: 'documents' },
    settings: { href: 'settings', full: '/me/settings' },
    payments: { href: 'payments' },
    payment: { href: 'payment/:distributionId' },
  },
  company: {
    href: '/company/:companyId',
    settings: { href: 'settings' },
    documents: { href: 'documents' },
    distributions: { href: 'distributions' },
    distribution: { href: 'distribution/:distributionId' },
    captable: {
      href: 'captable',
      rmt: {
        href: 'rmt',
        movement: { href: 'rmt/:movementId' },
      },
      assets: { href: 'assets' },
    },
    option: { href: 'option/:assetId' },
    fundraising: {
      href: 'fundraising/:operationId',
      full: '/company/:companyId/fundraising/:operationId',
      subscription: { href: ':subscriptionId' },
    },
    newFundraising: {
      private: {
        href: 'new/fundraising/private',
        full: '/company/:companyId/new/fundraising/private',
        fromOpportunity: {
          href: 'from/:opportunityId',
          full: '/company/:companyId/new/fundraising/private/from/:opportunityId',
        },
        edit: {
          href: ':operationId',
          full: '/company/:companyId/new/fundraising/private/:operationId',
          documentation: { href: 'documentation' },
          iban: { href: 'iban' },
          subscribers: { href: 'subscribers' },
          summary: { href: 'summary' },
        },
      },
      crowdfunding: {
        href: 'new/fundraising/crowdfunding',
        full: '/company/:companyId/new/fundraising/crowdfunding',
        edit: {
          href: ':operationId',
          full: '/company/:companyId/new/fundraising/crowdfunding/:operationId',
          documentation: { href: 'documentation' },
          iban: { href: 'iban' },
          summary: { href: 'summary' },
        },
      },
    },
    secondary: {
      href: 'secondary/:operationId',
      full: '/company/:companyId/secondary/:operationId',
      subscription: { href: ':subscriptionId' },
    },
    newSecondary: {
      href: 'new/secondary',
      full: '/company/:companyId/new/secondary',
      fromOpportunity: {
        href: 'from/:opportunityId',
        full: '/company/:companyId/new/secondary/from/:opportunityId',
      },
      edit: {
        href: ':operationId',
        full: '/company/:companyId/new/secondary/:operationId',
        sellers: { href: 'sellers' },
        buyers: { href: 'buyers' },
        summary: { href: 'summary' },
      },
    },
    opportunity: {
      href: 'opportunity/:operationId',
      full: '/company/:companyId/opportunity/:operationId',
      subscription: { href: ':subscriptionId' },
    },
    newOpportunity: {
      href: 'new/opportunity',
      full: '/company/:companyId/new/opportunity',
      edit: {
        href: ':operationId',
        full: '/company/:companyId/new/opportunity/:operationId',
        documentation: { href: 'documentation' },
        summary: { href: 'summary' },
      },
    },
    repatriation: {
      href: 'repatriation',
      full: '/company/:companyId/repatriation',
      assets: { href: 'assets' },
      identities: { href: 'identities' },
      import: { href: 'import' },
      grants: { href: 'grants' },
      summary: { href: 'summary' },
    },
  },
  subscription: { href: 'subscription/:subscriptionId' },
  bank: {
    finalizeSwanOnboarding: { href: 'finalize-swan-onboarding' },
    initiatePayments: {
      href: 'initiate-payments',
      distribution: {
        href: 'distribution/:distributionId',
        full: 'initiate-payments/distribution/:distributionId',
        transfer: {
          href: ':transferId',
          full: 'initiate-payments/distribution/:distributionId/:transferId',
        },
      },
      schedule: {
        href: 'schedule/:subscriptionId',
        full: 'initiate-payments/schedule/:subscriptionId',
      },
      refund: {
        href: 'refund/:subscriptionId',
        full: 'initiate-payments/refund/:subscriptionId',
        searchParams: '?amount=:amount&label=:label&bankTransferId=:bankTransferId',
      },
    },
  },
  space: {
    href: '/space',
    individuals: { href: 'individuals', full: '/space/individuals' },
    individual: {
      href: 'individual/:individualIdentityId',
      full: '/space/individual/:individualIdentityId',
    },
    companies: { href: 'companies', full: '/space/companies' },
    company: { href: 'company/:companyId', full: '/space/company/:companyId' },
    registrations: { href: 'registrations', full: '/space/registrations' },
    spaces: { href: 'spaces', full: '/space/spaces' },
    space: { href: 'space/:spaceId' },
    controls: { href: 'controls', full: '/space/controls' },
    managements: { href: 'managements', full: '/space/managements' },
    operations: { href: 'operations', full: '/space/operations' },
    bankTransfers: { href: 'bank-transfers', full: '/space/bank-transfers' },
    graphql: { href: 'graphql', full: '/space/graphql' },
    members: { href: 'members', full: '/space/members' },
  },
  manage: {
    href: '/manage',
    company: { href: 'company/:companyId', full: '/manage/company/:companyId' },
    individual: {
      href: 'individual/:individualIdentityId',
      full: '/manage/individual/:individualIdentityId',
    },
  },
  onboardings: {
    spv: {
      href: '/new/spv',
      edit: {
        href: ':companyId',
        full: '/new/spv/:companyId',
        compensation: { href: 'compensation' },
        representatives: { href: 'representatives' },
        access: { href: 'access' },
        summary: { href: 'summary' },
        shareholders: { href: 'shareholders' },
        matriculation: { href: 'matriculation', full: '/new/spv/:companyId/matriculation' },
      },
      fromSpace: { href: 'from/:spaceId', full: '/manage/new/spv/from/:spaceId' },
    },
    company: {
      href: '/new/company',
      edit: {
        href: ':companyId',
        full: '/new/company/:companyId',
        representatives: { href: 'representatives' },
        access: { href: 'access' },
        summary: { href: 'summary' },
        ubos: { href: 'ubos' },
      },
      fromSpace: { href: 'from/:spaceId', full: '/manage/new/company/from/:spaceId' },
    },
  },
  verifyEmail: { href: '/verify-email' },
  onboarding: { href: '/onboarding' },
  forgotPassword: { href: '/forgot-password' },
  resetPassword: { href: '/reset-password' },
  invitationDeclined: { href: '/invitation-declined' },
};
