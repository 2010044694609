import {
  Button,
  Divider,
  FormControl,
  FormLabel,
  Input,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  theme,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Select } from 'chakra-react-select';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import { IntlMemberRoles } from '@blockpulse3/data/locales/types';
import { Exceptions, noop } from '@blockpulse3/data/shared';
import { MemberStatus, useInviteMemberMutation } from '@blockpulse3/graphql/hooks';
import {
  ErrorMessage,
  ResponsiveModal,
  ResponsiveModalFooter,
  ResponsiveModalProps,
  useErrorToast,
} from '@blockpulse3/ui/commons';
import { useAuthUser } from '@blockpulse3/web-client/auth';

import { schema, spaceMemberFormDefaultValues } from './schema';
import { SpaceMemberForm } from './types';
import { getMemberRoleOptions } from './utils';

type Props = Omit<ResponsiveModalProps, 'onClose' | 'children'> & {
  /* ** Member default form values ** */
  defaultValues?: SpaceMemberForm;
  /* ** Callback on form submit success ** */
  onSubmitSuccess?: (status: MemberStatus) => void;
  /* ** Callback on form submit error ** */
  onSubmitError?: () => void;
  /* ** ResponsiveModal `onClose` callback ** */
  onClose: () => void;
};

export function NewSpaceMemberForm({
  defaultValues = spaceMemberFormDefaultValues,
  onClose,
  onSubmitSuccess = noop,
  onSubmitError = noop,
  ...props
}: Props): JSX.Element {
  const t = useTranslations();
  const i18nMemberRole = useTranslations('MemberRoles');

  const { spaceId: routeSpaceId = '' } = useParams();
  const { spaceId: authSpaceId = '' } = useAuthUser();
  const spaceId = routeSpaceId || authSpaceId;

  const errorToast = useErrorToast();

  const memberRoleOptions = getMemberRoleOptions();

  const [inviteMember] = useInviteMemberMutation();

  const { register, control, formState, handleSubmit, reset } = useForm<SpaceMemberForm>({
    defaultValues,
    resolver: yupResolver(schema),
  });

  const handleFormSubmit: SubmitHandler<SpaceMemberForm> = (data) => {
    const { firstName, lastName, email } = data;
    const roles = data.roles.map((role) => role.value);

    inviteMember({
      variables: {
        inviteMemberInput: {
          spaceId,
          roles,
          firstName,
          lastName,
          email,
        },
      },
      onCompleted: (res) => {
        reset({ ...defaultValues });
        onSubmitSuccess(res.inviteMember);
      },
      onError: (err) => {
        const error = err as Error;
        if (error.message === Exceptions.AlreadySpaceMember) {
          errorToast({ title: t('UserAlreadyIsSpaceMember') });
        }
        onSubmitError();
      },
    });
  };

  const handleSpaceMemberModalClose = (): void => {
    reset({ ...defaultValues });
    onClose();
  };

  return (
    <ResponsiveModal onClose={handleSpaceMemberModalClose} {...props}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{t('AddMember')}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <form id="add-space" onSubmit={handleSubmit(handleFormSubmit)}>
            <Stack spacing="4">
              <Stack direction={{ base: 'column', md: 'row' }} spacing="4">
                <FormControl isInvalid={!!formState.errors.firstName}>
                  <FormLabel>{t('FirstName')}</FormLabel>
                  <Input type="text" {...register('firstName')} />
                  <ErrorMessage error={formState.errors.firstName} />
                </FormControl>
                <FormControl isInvalid={!!formState.errors.lastName}>
                  <FormLabel>{t('LastName')}</FormLabel>
                  <Input type="text" {...register('lastName')} />
                  <ErrorMessage error={formState.errors.lastName} />
                </FormControl>
              </Stack>
              <FormControl isInvalid={!!formState.errors.email}>
                <FormLabel>{t('Email', { nb: 1 })}</FormLabel>
                <Input type="email" {...register('email')} />
                <ErrorMessage error={formState.errors.email} />
              </FormControl>
              <Controller
                control={control}
                name="roles"
                render={({ field }): JSX.Element => (
                  <FormControl isInvalid={!!formState.errors.roles}>
                    <FormLabel htmlFor="roles">{t('Role', { nb: 2 })}</FormLabel>
                    <Select
                      id="role"
                      isMulti
                      menuPlacement="bottom"
                      menuPortalTarget={document.body}
                      options={memberRoleOptions}
                      getOptionLabel={(option): string =>
                        i18nMemberRole(option.value as IntlMemberRoles)
                      }
                      styles={{
                        menuPortal: (provided) => ({
                          ...provided,
                          zIndex: theme.zIndices.modal + 1,
                        }),
                      }}
                      {...field}
                    />
                  </FormControl>
                )}
              />
            </Stack>
          </form>
        </ModalBody>
        <Divider />
        <ResponsiveModalFooter>
          <Button type="button" variant="secondary" onClick={handleSpaceMemberModalClose}>
            {t('Cancel')}
          </Button>
          <Button form="add-space" type="submit">
            {t('Validate')}
          </Button>
        </ResponsiveModalFooter>
      </ModalContent>
    </ResponsiveModal>
  );
}

export type NewSpaceMemberFormProps = Props;
