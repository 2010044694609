import { ChevronDownIcon, SearchIcon } from '@chakra-ui/icons';
import {
  Button,
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Stack,
  useDisclosure,
} from '@chakra-ui/react';
import { XCircleIcon } from '@heroicons/react/outline';
import { useState } from 'react';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import { noop, routes } from '@blockpulse3/data/shared';
import { ManagementType, MemberRole } from '@blockpulse3/graphql/hooks';
import { useAuthUser } from '@blockpulse3/web-client/auth';

import { ImportSpaceIdentitiesModal } from './ImportSpaceIdentitiesModal/ImportSpaceIdentitiesModal';

type Props = {
  type: ManagementType;
  /* ** Callback on search submit ** */
  onSearchSubmit: (value: string) => void;
};

export function SpaceManagementsControls({ type, onSearchSubmit = noop }: Props): JSX.Element {
  const t = useTranslations();

  const navigate = useNavigate();

  const { spaceId: authSpaceId = '', user: authUser } = useAuthUser();
  const { spaceId = authSpaceId } = useParams();

  const [searchInput, setSearchInput] = useState<string>('');

  const importIdentitiesModal = useDisclosure();

  const memberRoles = authUser?.members?.[0].roles;
  const isSuperAdmin = authUser?.isSuperAdmin;
  const hasAdminRights =
    isSuperAdmin ||
    (memberRoles &&
      (memberRoles.includes(MemberRole.ADMIN) || memberRoles.includes(MemberRole.OWNER)));
  const displayOwnerActions = type === ManagementType.OWNER && hasAdminRights;
  const displayManagerActions = type === ManagementType.MANAGER && hasAdminRights;

  const navigateToCompanyOnboarding = (): void =>
    navigate(generatePath(routes.onboardings.company.fromSpace.full, { spaceId }));
  const navigateToSpvOnboarding = (): void =>
    navigate(generatePath(routes.onboardings.spv.fromSpace.full, { spaceId }));

  const handleSearchInputChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setSearchInput(e.target.value);
  };

  const handleSearchInputReset = (): void => {
    setSearchInput('');
    onSearchSubmit('');
  };

  const handleSearchInputSubmit = (): void => {
    onSearchSubmit(searchInput);
  };

  return (
    <Stack
      alignItems={{ base: 'flex-start', md: 'flex-end' }}
      direction={{ base: 'column', md: 'row' }}
      justifyContent="space-between"
    >
      <InputGroup width={{ base: 'full', md: '300px' }}>
        <Input
          paddingRight="80px"
          placeholder={t('Search')}
          value={searchInput}
          onChange={handleSearchInputChange}
          onKeyDown={(e): void => {
            if (e.key === 'Enter') {
              handleSearchInputSubmit();
            }
          }}
        />
        <InputRightElement
          flexDirection="row-reverse"
          justifyContent="space-between"
          paddingX="1"
          width="80px"
        >
          <IconButton
            isDisabled
            aria-label="search-managers"
            icon={<Icon as={SearchIcon} />}
            size="sm"
            variant="secondary"
            onClick={handleSearchInputSubmit}
          />
          {!!searchInput && (
            <IconButton
              aria-label="reset"
              icon={<Icon as={XCircleIcon} />}
              size="sm"
              variant="ghost"
              onClick={handleSearchInputReset}
            />
          )}
        </InputRightElement>
      </InputGroup>
      {displayOwnerActions && (
        <Menu>
          <MenuButton as={Button} rightIcon={<ChevronDownIcon />} variant="secondary">
            {t('Action', { nb: 2 })}
          </MenuButton>
          <MenuList>
            <MenuItem fontWeight="600" onClick={navigateToCompanyOnboarding}>
              {t('ExistingCompanyImportAction')}
            </MenuItem>
            <MenuItem fontWeight="600" onClick={navigateToSpvOnboarding}>
              {t('CreateSPVLong')}
            </MenuItem>
          </MenuList>
        </Menu>
      )}
      {displayManagerActions && (
        <>
          <Menu>
            <MenuButton as={Button} rightIcon={<ChevronDownIcon />} variant="secondary">
              {t('Action', { nb: 2 })}
            </MenuButton>
            <MenuList>
              <MenuItem fontWeight="600" onClick={importIdentitiesModal.onOpen}>
                {t('IdentitiesImportAction')}
              </MenuItem>
            </MenuList>
          </Menu>
          {importIdentitiesModal.isOpen && (
            <ImportSpaceIdentitiesModal
              isOpen={importIdentitiesModal.isOpen}
              type={type}
              onClose={importIdentitiesModal.onClose}
            />
          )}
        </>
      )}
    </Stack>
  );
}

export type SpaceManagementsControlsProps = Props;
