import {
  Alert,
  AlertIcon,
  AlertTitle,
  Button,
  Divider,
  FormControl,
  FormLabel,
  Icon,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  Switch,
  Text,
} from '@chakra-ui/react';
import { DocumentTextIcon } from '@heroicons/react/outline';
import { yupResolver } from '@hookform/resolvers/yup';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import { IntlCSVParseError } from '@blockpulse3/data/locales/types';
import {
  CsvCellError,
  CsvError,
  Exceptions,
  FormErrors,
  IdentityType,
  ManagerFilterType,
  SpaceDocumentType,
  noop,
} from '@blockpulse3/data/shared';
import {
  GetManagementsDocument,
  GetManagementsQuery,
  GetManagementsQueryVariables,
  ManagementType,
  ManagerEntityInfosFragment,
  useCreateSpaceImportedIdentitiesMutation,
  useDeleteSpaceDocumentMutation,
  useGetSpaceDocumentCsvDataQuery,
  useGetSpaceDocumentQuery,
} from '@blockpulse3/graphql/hooks';
import {
  CsvDataTable,
  DownloadCsvTemplate,
  DropzoneInput,
  ErrorMessage,
  ErrorQueryModal,
  ResponsiveModal,
  ResponsiveModalFooter,
  ResponsiveModalProps,
  SkeletonModal,
} from '@blockpulse3/ui/commons';
import { usePagination } from '@blockpulse3/ui/ui-hooks';
import { useAuthUser } from '@blockpulse3/web-client/auth';

import { PAGE_SIZE } from '../../utils';
import { importSpaceIdentitiesSchema } from './schema';
import { ImportSpaceIdentitiesForm } from './types';

type Props = {
  type: ManagementType;
  onClose: () => void;
} & Omit<ResponsiveModalProps, 'children'>;

export function ImportSpaceIdentitiesModal({ type, onClose = noop, ...props }: Props): JSX.Element {
  const t = useTranslations();
  const i18nCSVParseError = useTranslations('CSVParseError');

  const { spaceId: authSpaceId = '' } = useAuthUser();
  const { spaceId = authSpaceId } = useParams();

  const {
    register,
    control,
    formState,
    setValue,
    setError,
    clearErrors,
    handleSubmit,
    getValues,
    watch,
  } = useForm<ImportSpaceIdentitiesForm>({
    defaultValues: { individualIdentitiesFile: '', companyIdentitiesFile: '' },
    resolver: yupResolver(importSpaceIdentitiesSchema),
  });

  const disableIndividualIdentities = watch('disableIndividualIdentities');
  const disableCompanyIdentities = watch('disableCompanyIdentities');

  const [uploading, setUploading] = useState<IdentityType | null>(null);

  const [csvIdentityData, setCsvIdentityData] = useState<Record<string, string>[]>([]);
  const [csvIdentityErrors, setCsvIdentityErrors] = useState<CsvCellError[]>([]);

  const [csvCompanyData, setCsvCompanyData] = useState<Record<string, string>[]>([]);
  const [csvCompanyErrors, setCsvCompanyErrors] = useState<CsvCellError[]>([]);

  const { refetch: refetchManagements } = usePagination<
    GetManagementsQuery,
    GetManagementsQueryVariables,
    ManagerEntityInfosFragment
  >({
    queryDocument: GetManagementsDocument,
    queryOptions: {
      variables: {
        spaceId,
        first: PAGE_SIZE,
        filterBy: [{ name: '', type: ManagerFilterType.MANAGER_TYPE, value: type }],
      },
      skip: true,
    },
    dataName: 'managers',
    pageSize: PAGE_SIZE,
  });

  const { data: csvIdentitiesData, refetch: refetchCsvIdentities } =
    useGetSpaceDocumentCsvDataQuery({
      variables: {
        spaceId,
        documentType: SpaceDocumentType.INDIVIDUAL_IDENTITIES_IMPORT,
      },
      skip: !spaceId,
      fetchPolicy: 'network-only',
    });

  const { data: csvCompanyIdentitiesData, refetch: refetchCompanyCsvIdentities } =
    useGetSpaceDocumentCsvDataQuery({
      variables: {
        spaceId,
        documentType: SpaceDocumentType.COMPANY_IDENTITIES_IMPORT,
      },
      skip: !spaceId,
      fetchPolicy: 'network-only',
    });

  const {
    data: indivData,
    loading: indivLoading,
    error: IndivError,
    refetch: refetchIndiv,
  } = useGetSpaceDocumentQuery({
    variables: {
      spaceId,
      documentType: SpaceDocumentType.INDIVIDUAL_IDENTITIES_IMPORT,
    },
    skip: !spaceId,
    onCompleted: (data) => {
      setValue('individualIdentitiesFile', data?.getSpaceDocument?.document.title);
    },
  });

  const {
    data: compData,
    loading: compLoading,
    error: compError,
    refetch: refetchComp,
  } = useGetSpaceDocumentQuery({
    variables: {
      spaceId,
      documentType: SpaceDocumentType.COMPANY_IDENTITIES_IMPORT,
    },
    skip: !spaceId,
    onCompleted: (data) => {
      setValue('companyIdentitiesFile', data?.getSpaceDocument?.document.title);
    },
  });

  const [deleteSpaceDocument, { loading: deleteDocumentLoading }] =
    useDeleteSpaceDocumentMutation();
  const [createSpaceImportedIdentities, { loading: importIdentitiesLoading }] =
    useCreateSpaceImportedIdentitiesMutation();

  useEffect(() => {
    if (getValues('individualIdentitiesFile')) {
      setCsvIdentityData(csvIdentitiesData?.getSpaceDocumentCsvData || []);
    }

    if (getValues('companyIdentitiesFile')) {
      setCsvCompanyData(csvCompanyIdentitiesData?.getSpaceDocumentCsvData || []);
    }
  }, [csvIdentitiesData, csvCompanyIdentitiesData, getValues]);

  if (indivLoading || compLoading) {
    return (
      <ResponsiveModal onClose={onClose} {...props}>
        <ModalOverlay />
        <SkeletonModal minHeight="350px" />
      </ResponsiveModal>
    );
  }

  if (IndivError || compError) {
    return (
      <ResponsiveModal onClose={onClose} {...props}>
        <ModalOverlay />
        <ErrorQueryModal />
      </ResponsiveModal>
    );
  }

  const indivDocument = indivData?.getSpaceDocument?.document;
  const indivFileName = indivDocument
    ? { [indivDocument.id]: new File([indivDocument.title], indivDocument.title) }
    : {};

  const compDocument = compData?.getSpaceDocument?.document;
  const compFileName = compDocument
    ? { [compDocument.id]: new File([compDocument.title], compDocument.title) }
    : {};

  const handleFileDrop = async (
    identityType: IdentityType,
    acceptedFiles: File[],
  ): Promise<void> => {
    clearErrors(
      identityType === IdentityType.COMPANY ? 'companyIdentitiesFile' : 'individualIdentitiesFile',
    );

    const formData = new FormData();
    formData.append('identities', acceptedFiles[0]);
    formData.append('spaceId', spaceId);
    formData.append('identityType', identityType);
    const isCompany = identityType === IdentityType.COMPANY;

    setUploading(identityType);
    setValue(isCompany ? 'companyIdentitiesFile' : 'individualIdentitiesFile', undefined);
    isCompany ? setCsvCompanyData([]) : setCsvIdentityData([]);
    await axios
      .post(
        process.env['NX_API_CONTROLLER_ENDPOINT'] + '/spaces/uploadIdentitiesDocument',
        formData,
        {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'multipart/form-data',
          },
        },
      )
      .then(() => {
        setUploading(null);
        setValue(isCompany ? 'companyIdentitiesFile' : 'individualIdentitiesFile', 'file.csv');
        isCompany ? setCsvCompanyErrors([]) : setCsvIdentityErrors([]);
        isCompany ? refetchComp() : refetchIndiv();
        isCompany ? refetchCompanyCsvIdentities() : refetchCsvIdentities();
      })
      .catch((err: unknown) => {
        if (axios.isAxiosError(err)) {
          const error = err?.response?.data as CsvError;
          const errorMessage = error?.message ?? error.message;
          const errorCsvData = error?.data || [];
          const errorCsvCells = error?.cellErrors || [];

          isCompany ? setCsvCompanyData(errorCsvData) : setCsvIdentityData(errorCsvData);
          isCompany ? setCsvCompanyErrors(errorCsvCells) : setCsvIdentityErrors(errorCsvCells);

          if (!error.code && errorMessage === Exceptions.MissingRequiredProperties) {
            setError(isCompany ? 'companyIdentitiesFile' : 'individualIdentitiesFile', {
              type: 'custom',
              message: t('SomeRequiredInfoMissing'),
            });
          } else {
            const code = error.code || 'DefaultError';
            setError(isCompany ? 'companyIdentitiesFile' : 'individualIdentitiesFile', {
              type: 'custom',
              message: i18nCSVParseError(code as IntlCSVParseError),
            });
          }
        } else {
          setError(isCompany ? 'companyIdentitiesFile' : 'individualIdentitiesFile', {
            type: 'custom',
            message: FormErrors.DropzoneInvalidTemplate,
          });
        }
        setUploading(null);
      });
  };

  const handleFileDelete = (identityType: IdentityType): void => {
    const isCompany = identityType === IdentityType.COMPANY;
    const documentType = isCompany
      ? SpaceDocumentType.COMPANY_IDENTITIES_IMPORT
      : SpaceDocumentType.INDIVIDUAL_IDENTITIES_IMPORT;
    deleteSpaceDocument({
      variables: {
        deleteSpaceDocumentInput: {
          spaceId,
          documentType,
        },
      },
      onCompleted: () => {
        setValue(isCompany ? 'companyIdentitiesFile' : 'individualIdentitiesFile', undefined);
        isCompany ? setCsvCompanyData([]) : setCsvIdentityData([]);
        isCompany ? refetchComp() : refetchIndiv();
        isCompany ? refetchCompanyCsvIdentities() : refetchCsvIdentities();
      },
    });
  };

  const handleFormSubmit: SubmitHandler<ImportSpaceIdentitiesForm> = (data): void => {
    createSpaceImportedIdentities({
      variables: {
        spaceId,
      },
      onCompleted: () => {
        refetchManagements();
        refetchIndiv();
        refetchComp();
        onClose();
      },
    });
  };

  return (
    <ResponsiveModal size="2xl" onClose={onClose} {...props}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{t('IdentitiesImportAction')}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <form id="upload-space-identities-files" onSubmit={handleSubmit(handleFormSubmit)}>
            <Stack spacing="8">
              <Stack>
                <Text fontWeight="500">{t('IndividualIdentitiesLabel')}</Text>
                <Alert status="info">
                  <AlertIcon />
                  <AlertTitle>
                    <DownloadCsvTemplate path={'/assets/individual_identities_template.csv'} />
                  </AlertTitle>
                </Alert>
                <Controller
                  control={control}
                  name="individualIdentitiesFile"
                  render={(): JSX.Element => (
                    <FormControl>
                      <DropzoneInput
                        accept={{ 'text/csv': [] }}
                        files={indivFileName}
                        isDisabled={disableIndividualIdentities}
                        isLoading={indivLoading || uploading === IdentityType.INDIVIDUAL}
                        maxFiles={1}
                        subTitle=".CSV"
                        icon={
                          <Icon
                            as={DocumentTextIcon}
                            boxSize="42px"
                            color="gray.400"
                            strokeWidth="1px"
                          />
                        }
                        onDelete={(): void => handleFileDelete(IdentityType.INDIVIDUAL)}
                        onDrop={(files: File[]): Promise<void> =>
                          handleFileDrop(IdentityType.INDIVIDUAL, files)
                        }
                      />
                      <ErrorMessage error={formState.errors?.individualIdentitiesFile} />
                    </FormControl>
                  )}
                />
                <CsvDataTable cellErrors={csvIdentityErrors} csvData={csvIdentityData} />
                <FormControl alignItems="center" display="flex">
                  <Switch {...register('disableIndividualIdentities')} />
                  <FormLabel
                    fontSize="sm"
                    fontWeight="400"
                    htmlFor="disableIndividualIdentities"
                    mb="0"
                    ml="3"
                  >
                    {t('DisableIndividualIdentitiesUpload')}
                  </FormLabel>
                </FormControl>
              </Stack>
              <Stack>
                <Text fontWeight={500}>{t('CompanyIdentitiesLabel')}</Text>
                <Alert status="info">
                  <AlertIcon />
                  <AlertTitle>
                    <DownloadCsvTemplate path={'/assets/company_identities_template.csv'} />
                  </AlertTitle>
                </Alert>
                {!!csvIdentityErrors?.length && !disableIndividualIdentities && (
                  <Alert status="warning">
                    <AlertIcon />
                    <AlertTitle>{t('ImportCompanyIdentitiesWarning')}</AlertTitle>
                  </Alert>
                )}
                <Controller
                  control={control}
                  name="companyIdentitiesFile"
                  render={(): JSX.Element => (
                    <FormControl>
                      <DropzoneInput
                        accept={{ 'text/csv': [] }}
                        files={compFileName}
                        isLoading={compLoading || uploading === IdentityType.COMPANY}
                        maxFiles={1}
                        subTitle=".CSV"
                        icon={
                          <Icon
                            as={DocumentTextIcon}
                            boxSize="42px"
                            color="gray.400"
                            strokeWidth="1px"
                          />
                        }
                        isDisabled={
                          disableCompanyIdentities ||
                          ((!!csvIdentityErrors?.length || !csvIdentityData?.length) &&
                            !disableIndividualIdentities)
                        }
                        onDelete={(): void => handleFileDelete(IdentityType.COMPANY)}
                        onDrop={(files: File[]): Promise<void> =>
                          handleFileDrop(IdentityType.COMPANY, files)
                        }
                      />
                      <ErrorMessage error={formState.errors?.companyIdentitiesFile} />
                    </FormControl>
                  )}
                />
                <CsvDataTable cellErrors={csvCompanyErrors} csvData={csvCompanyData} />
                <FormControl alignItems="center" display="flex">
                  <Switch {...register('disableCompanyIdentities')} />
                  <FormLabel
                    fontSize="sm"
                    fontWeight="400"
                    htmlFor="disableCompanyIdentities"
                    mb="0"
                    ml="3"
                  >
                    {t('DisableCompanyIdentitiesUpload')}
                  </FormLabel>
                </FormControl>
              </Stack>
            </Stack>
          </form>
        </ModalBody>
        <Divider />
        <ResponsiveModalFooter>
          <Button
            form="upload-space-identities-files"
            isLoading={importIdentitiesLoading}
            type="submit"
            isDisabled={
              !!uploading ||
              deleteDocumentLoading ||
              (disableCompanyIdentities && disableIndividualIdentities)
            }
          >
            {t('ImportAction')}
          </Button>
        </ResponsiveModalFooter>
      </ModalContent>
    </ResponsiveModal>
  );
}
