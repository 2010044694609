import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Divider,
  Heading,
  Skeleton,
  Stack,
  Text,
} from '@chakra-ui/react';
import { generatePath, useMatch, useNavigate, useParams } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import { routes } from '@blockpulse3/data/shared';
import {
  CompanyStatus,
  RepresentativeRole,
  useUpdateCompanyMutation,
} from '@blockpulse3/graphql/hooks';
import {
  formatDate,
  formatNationality,
  formatNumberCurrency,
  isCompanyComplete,
} from '@blockpulse3/helpers';
import {
  CardIdentity,
  ErrorQueryCard,
  useErrorToast,
  useSuccessToast,
  useWarningToast,
} from '@blockpulse3/ui/commons';

import { useGetOnboardingExistingCompany } from '../hooks';

/**
 * OnboardingExistingCompanySummaryStep.
 * Summary of the existing company creation.
 *
 * @param {Props}
 * @returns {JSX.Element}
 */
export function OnboardingExistingCompanySummary(): JSX.Element {
  const t = useTranslations();
  const i18nRoleValues = useTranslations('RoleValues');

  const errorToast = useErrorToast();
  const warningToast = useWarningToast();
  const successToast = useSuccessToast();

  const navigate = useNavigate();
  const { companyId = '' } = useParams();

  const { data, loading, error } = useGetOnboardingExistingCompany();

  const [updateCompany] = useUpdateCompanyMutation();

  const isSpaceRegistration = useMatch(routes.manage.href + '/*');

  const handleStepCancel = (): void => {
    navigate('../' + routes.onboardings.company.edit.access.href);
  };

  const handleStepSubmit = (): void => {
    if (isCompanyComplete(data?.company)) {
      updateCompany({
        variables: {
          updateCompanyInput: {
            companyId,
            status: CompanyStatus.RUNNING,
          },
        },
        onCompleted: async () => {
          successToast({ title: t('CompanyAdded') });
          navigate(
            generatePath(isSpaceRegistration ? routes.manage.company.full : routes.company.href, {
              companyId,
            }),
          );
        },
        onError: () => {
          errorToast({ title: t('ErrorAddingCompany') });
        },
      });
    } else {
      warningToast({ title: t('IncompleteCompany') });
      navigate(generatePath(routes.onboardings.company.edit.href, { companyId }));
    }
  };

  if (loading) {
    return <Skeleton height="350px" width="full" />;
  }

  if (error) {
    return <ErrorQueryCard width="full" />;
  }

  if (!data) {
    return <ErrorQueryCard width="full" />;
  }

  const presidents = data.company.companyRepresentative.filter((rep) => {
    return (
      rep.role === RepresentativeRole.PRESIDENT || rep.role === RepresentativeRole.COMPANY_MANAGER
    );
  });

  const presidentsRepresentativeIdentityIds = presidents.map(
    (rep) => rep.representativeIdentity.id,
  );

  const otherRoles = data.company.companyRepresentative.filter((rep, i) => {
    return (
      rep.role !== RepresentativeRole.PRESIDENT &&
      rep.role !== RepresentativeRole.COMPANY_MANAGER &&
      !presidentsRepresentativeIdentityIds.find((id) => id === rep.representativeIdentity.id)
    );
  });

  return (
    <Card variant="divider-top" width="full">
      <CardHeader>
        <Heading size="lg">{t('Verification')}</Heading>
      </CardHeader>
      <Divider />
      <CardBody as={Stack} spacing="4">
        <Stack spacing="4">
          <Text fontSize="xl" fontWeight="bold">
            {t('CompanyInformation')} :
          </Text>
          <Stack bg="gray.50" borderRadius="md" p="3" spacing="1">
            <Text>
              <Text as="span" fontWeight="bold" mr="2">
                {t('CompanyName')} :
              </Text>
              {data.company.name}
            </Text>
            <Text>
              <Text as="span" fontWeight="bold" mr="2">
                {t('Nationality')} :
              </Text>
              {formatNationality(data.company.address?.country || 'FRA')}
            </Text>
            <Text>
              <Text as="span" fontWeight="bold" mr="2">
                {t('RegistrationNumber')} :
              </Text>
              {data.company?.registrationNumber}
            </Text>
            <Text>
              <Text as="span" fontWeight="bold" mr="2">
                {t('CorporateForm')} :
              </Text>
              {data.company?.corporateForm}
            </Text>
            <Text>
              <Text as="span" fontWeight="bold" mr="2">
                {t('CreationDate')} :
              </Text>
              {formatDate(data.company?.creationDate, 'D MMMM YYYY')}
            </Text>
            <Text>
              <Text as="span" fontWeight="bold" mr="2">
                {t('ShareCapital')} :
              </Text>
              {formatNumberCurrency(data.company?.shareCapital || '')}
            </Text>
          </Stack>
        </Stack>
        <Stack spacing="4">
          <Text fontSize="xl" fontWeight="bold">
            {t('RolesAndAccess')}
          </Text>
          {presidents.map((president) => {
            const identity = president.representativeIdentity;
            const subTitle = identity.name;

            return (
              <CardIdentity
                key={president.id}
                subTitle={subTitle}
                title={i18nRoleValues(RepresentativeRole.PRESIDENT)}
                type={identity.type}
              />
            );
          })}
          {otherRoles.map((otherRole) => {
            const identity = otherRole.representativeIdentity;
            const subTitle = identity.name;

            // if role is CREATOR display in front "Collaborateur"
            const roleTitle =
              otherRole.role === RepresentativeRole.CREATOR
                ? i18nRoleValues(RepresentativeRole.VIEWER)
                : i18nRoleValues(otherRole.role);
            return (
              <CardIdentity
                key={identity.id}
                subTitle={subTitle}
                title={roleTitle}
                type={identity.type}
              />
            );
          })}
        </Stack>
      </CardBody>
      <CardFooter as={Stack} direction={{ base: 'column', md: 'row' }} spacing="4">
        <Button variant="secondary" w="full" onClick={handleStepCancel}>
          {t('Back')}
        </Button>
        <Button data-cy="next" w="full" onClick={handleStepSubmit}>
          {t('Next')}
        </Button>
      </CardFooter>
    </Card>
  );
}
