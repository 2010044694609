import {
  Button,
  Divider,
  FormControl,
  FormLabel,
  Input,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  Tooltip,
  theme,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Select } from 'chakra-react-select';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslations } from 'use-intl';

import { IntlMemberRoles } from '@blockpulse3/data/locales/types';
import { noop } from '@blockpulse3/data/shared';
import {
  Member,
  MemberRole,
  useGetMemberQuery,
  useUpdateMemberMutation,
} from '@blockpulse3/graphql/hooks';
import {
  ErrorMessage,
  ErrorQueryModal,
  ResponsiveModal,
  ResponsiveModalFooter,
  ResponsiveModalProps,
  SkeletonModal,
  useErrorToast,
  useSuccessToast,
} from '@blockpulse3/ui/commons';
import { useAuthUser } from '@blockpulse3/web-client/auth';

import { schema } from './schema';
import { SpaceMemberForm } from './types';
import { getMember, getMemberRoleOptions } from './utils';

type Props = Omit<ResponsiveModalProps, 'children'> & {
  /* ** Member to update ** */
  memberId: Member['id'];
  /* ** Callback on form submit success ** */
  onSubmitSuccess?: () => void;
  /* ** Callback on form submit error ** */
  onSubmitError?: () => void;
};

export function UpdateSpaceMemberForm({
  memberId,
  onSubmitSuccess = noop,
  onSubmitError = noop,
  ...props
}: Props): JSX.Element {
  const t = useTranslations();
  const i18nMemberRole = useTranslations('MemberRoles');

  const { user: authUser } = useAuthUser();

  const errorToast = useErrorToast();
  const successToast = useSuccessToast();

  const memberRoleOptions = getMemberRoleOptions();

  const { register, control, formState, handleSubmit, reset } = useForm<SpaceMemberForm>({
    resolver: yupResolver(schema),
  });

  const [updateMember] = useUpdateMemberMutation();

  const { data, loading, error } = useGetMemberQuery({
    variables: { memberId },
    onCompleted: ({ member }) => {
      const memberForm = getMember(member);
      reset({ ...memberForm });
    },
  });

  const handleFormSubmit: SubmitHandler<SpaceMemberForm> = (data) => {
    const roles = data.roles.map((role) => role.value);

    updateMember({
      variables: {
        updateMemberInput: {
          memberId,
          roles,
        },
      },
      onCompleted: () => {
        successToast({ title: t('UpdateMemberSuccess') });
        onSubmitSuccess();
      },
      onError: () => {
        errorToast({ title: t('UpdateMemberError') });
        onSubmitError();
      },
    });
  };

  if (loading) {
    return (
      <ResponsiveModal {...props}>
        <ModalOverlay />
        <SkeletonModal minHeight="350px" />
      </ResponsiveModal>
    );
  }

  if (error) {
    return (
      <ResponsiveModal {...props}>
        <ModalOverlay />
        <ErrorQueryModal />
      </ResponsiveModal>
    );
  }

  if (!data) {
    return (
      <ResponsiveModal {...props}>
        <ModalOverlay />
        <ErrorQueryModal />
      </ResponsiveModal>
    );
  }

  const isOwner = data.member?.roles.includes(MemberRole.OWNER);
  const isUserMember = data.member?.user?.id === authUser?.id;
  const isRoleDisabled = isUserMember;

  let disabledRoleMsg = t('ChangeRoleForbidden');
  if (isOwner) {
    disabledRoleMsg = t('ChangeOwnerExplanation');
  }

  return (
    <ResponsiveModal {...props}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{t('UpdateMember')}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <form id="add-space" onSubmit={handleSubmit(handleFormSubmit)}>
            <Stack spacing="4">
              <FormControl isInvalid={!!formState.errors.firstName}>
                <FormLabel>{t('FirstName')}</FormLabel>
                <Input isDisabled type="text" {...register('firstName')} />
                <ErrorMessage error={formState.errors.firstName} />
              </FormControl>
              <FormControl isInvalid={!!formState.errors.lastName}>
                <FormLabel>{t('LastName')}</FormLabel>
                <Input isDisabled type="text" {...register('lastName')} />
                <ErrorMessage error={formState.errors.lastName} />
              </FormControl>
              <FormControl isInvalid={!!formState.errors.email}>
                <FormLabel>{t('Email', { nb: 1 })}</FormLabel>
                <Input isDisabled type="email" {...register('email')} />
                <ErrorMessage error={formState.errors.email} />
              </FormControl>
              <Controller
                control={control}
                name="roles"
                render={({ field }): JSX.Element => (
                  <Tooltip
                    hasArrow
                    isDisabled={!isRoleDisabled}
                    label={<Text textAlign="center">{disabledRoleMsg}</Text>}
                    placement="top"
                  >
                    <FormControl isInvalid={!!formState.errors.roles}>
                      <FormLabel htmlFor="roles">{t('Role', { nb: 1 })}</FormLabel>
                      <Select
                        id="role"
                        isMulti
                        isDisabled={isRoleDisabled}
                        menuPlacement="bottom"
                        menuPortalTarget={document.body}
                        options={memberRoleOptions}
                        getOptionLabel={(option): string =>
                          i18nMemberRole(option.value as IntlMemberRoles)
                        }
                        styles={{
                          menuPortal: (provided) => ({
                            ...provided,
                            zIndex: theme.zIndices.modal + 1,
                          }),
                        }}
                        {...field}
                      />
                    </FormControl>
                  </Tooltip>
                )}
              />
            </Stack>
          </form>
        </ModalBody>
        <Divider />
        <ResponsiveModalFooter>
          <Button type="button" variant="secondary" onClick={props.onClose}>
            {t('Cancel')}
          </Button>
          <Button form="add-space" type="submit">
            {t('Validate')}
          </Button>
        </ResponsiveModalFooter>
      </ModalContent>
    </ResponsiveModal>
  );
}

export type UpdateSpaceMemberFormProps = Props;
