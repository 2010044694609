import { ChevronDownIcon, Icon } from '@chakra-ui/icons';
import {
  Button,
  Card,
  CardBody,
  HStack,
  Heading,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Stack,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { PlusIcon, SearchIcon, XCircleIcon } from '@heroicons/react/outline';
import { SortingState, Updater } from '@tanstack/react-table';
import { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import { IntlBankDistributionType } from '@blockpulse3/data/locales/types';
import {
  BankDistributionFilterInput,
  BankDistributionFilterType,
  BankDistributionInfosFragment,
  BankDistributionType,
  GetPaginatedBankDistributionsDocument,
  GetPaginatedBankDistributionsQuery,
  GetPaginatedBankDistributionsQueryVariables,
  OrderBy,
  OrderByDirection,
} from '@blockpulse3/graphql/hooks';
import { LinkButton } from '@blockpulse3/ui/commons';
import { usePagination } from '@blockpulse3/ui/ui-hooks';
import { useIdentity } from '@blockpulse3/web-client/auth';

import { CreateDistributionModal } from './CreateDistributionModal';
import { DistributionTable } from './DistributionTable/DistributionTable';
import { bankDistributionTypeOptions } from './schema';

const PAGE_SIZE = 10;
type Props = {
  backHref?: string;
};

/**
 * DistributionsView.
 *
 * @returns {JSX.Element}
 */
export function DistributionsView({ backHref }: Props): JSX.Element {
  const t = useTranslations();
  const i18nBankDistributionType = useTranslations('BankDistributionType');

  const { companyId = '' } = useParams();
  const { identityId } = useIdentity();

  const { isOpen, onOpen, onClose } = useDisclosure();

  const [selectedType, setSelectedType] = useState<BankDistributionType>();
  const [filters, setFilters] = useState<BankDistributionFilterInput[]>([]);
  const [searchInput, setSearchInput] = useState<string>('');
  const [sorting, setSorting] = useState<SortingState>([{ id: 'status', desc: true }]);

  const orderBy: OrderBy | undefined = useMemo(() => {
    if (!sorting.length) return undefined;
    return {
      direction: sorting[0].desc ? OrderByDirection.DESC : OrderByDirection.ASC,
      field: sorting[0].id,
    };
  }, [sorting]);

  const paginationProps = usePagination<
    GetPaginatedBankDistributionsQuery,
    GetPaginatedBankDistributionsQueryVariables,
    BankDistributionInfosFragment
  >({
    queryDocument: GetPaginatedBankDistributionsDocument,
    queryOptions: {
      variables: {
        identityId,
        filterBy: filters,
        first: PAGE_SIZE,
        orderBy,
      },
      skip: !identityId,
      fetchPolicy: 'cache-and-network',
    },
    dataName: 'bankDistributions',
    pageSize: PAGE_SIZE,
  });
  const { totalCount, refetch, reset } = paginationProps;

  const handleCreateBankDistribution = (bankDistributionType: BankDistributionType): void => {
    setSelectedType(bankDistributionType);
    onOpen();
  };

  const handleSortingChange = (sortFn: Updater<SortingState>): void => {
    setSorting(sortFn);
    refetch();
  };

  const resetSpecificFilter = (
    filterName: string,
    filterType: BankDistributionFilterType,
    value: string,
  ): void => {
    const currentFilterWithoutFilterName = filters.filter((filter) => filter.name !== filterName);
    const newFilter: BankDistributionFilterInput = {
      name: filterName,
      type: filterType,
      value,
    };
    reset();
    setFilters([...currentFilterWithoutFilterName, newFilter]);
  };

  const handleSearchInputSubmit = (value: string): void => {
    const isInputAndFilterSearchEmpty =
      value === '' && !filters.some((filter) => filter.name === 'searchValue');

    if (isInputAndFilterSearchEmpty) return;

    resetSpecificFilter('searchValue', BankDistributionFilterType.CUSTOM, value);
  };

  const handleSearchInputChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setSearchInput(e.target.value);
  };

  const handleSearchInputReset = (): void => {
    setSearchInput('');
    resetSpecificFilter('searchValue', BankDistributionFilterType.CUSTOM, '');
  };

  return (
    <Stack spacing="6">
      {backHref && <LinkButton label={t('Back')} route={backHref} />}
      <Heading>{t(companyId ? 'Distribution' : 'Payment', { nb: 2 })}</Heading>
      <Card>
        <CardBody as={Stack} spacing="4">
          <Stack direction={{ base: 'column-reverse', md: 'row' }} justifyContent="space-between">
            <Stack direction={{ base: 'column', md: 'row' }}>
              <InputGroup width={{ base: 'full', md: '300px' }}>
                <Input
                  paddingRight="80px"
                  placeholder={t('Search')}
                  value={searchInput}
                  onChange={handleSearchInputChange}
                  onKeyDown={(e): void => {
                    if (e.key === 'Enter') {
                      handleSearchInputSubmit(searchInput);
                    }
                  }}
                />
                <InputRightElement
                  flexDirection="row-reverse"
                  justifyContent="space-between"
                  paddingX="1"
                  width="80px"
                >
                  <IconButton
                    aria-label="search-database"
                    icon={<Icon as={SearchIcon} />}
                    size="sm"
                    variant="secondary"
                    onClick={(): void => handleSearchInputSubmit(searchInput)}
                  />
                  {!!searchInput && (
                    <IconButton
                      aria-label="reset"
                      icon={<Icon as={XCircleIcon} />}
                      size="sm"
                      variant="ghost"
                      onClick={handleSearchInputReset}
                    />
                  )}
                </InputRightElement>
              </InputGroup>
              <HStack>
                <Text
                  color="gray.800"
                  fontSize="sm"
                  px={{ base: '0', md: '3' }}
                  rounded="md"
                  textAlign="center"
                >
                  {t('ResultsCount', { nb: totalCount })}
                </Text>
              </HStack>
            </Stack>
            {companyId && (
              <Menu direction="rtl">
                <MenuButton
                  as={Button}
                  leftIcon={<Icon as={PlusIcon} boxSize="5" />}
                  rightIcon={<ChevronDownIcon />}
                >
                  {t('CreateNewDistribution')}
                </MenuButton>
                <MenuList>
                  {bankDistributionTypeOptions.map((option) => (
                    <MenuItem
                      key={option.value}
                      px="4"
                      onClick={(): void => handleCreateBankDistribution(option.value)}
                    >
                      {i18nBankDistributionType(option.label as IntlBankDistributionType)}
                    </MenuItem>
                  ))}
                </MenuList>
              </Menu>
            )}
          </Stack>
          <DistributionTable
            sorting={sorting}
            onSortingChange={handleSortingChange}
            {...paginationProps}
          />
        </CardBody>
      </Card>
      {selectedType && (
        <CreateDistributionModal
          bankDistributionType={selectedType}
          isOpen={isOpen}
          onClose={onClose}
          onComplete={(): void => refetch()}
        />
      )}
    </Stack>
  );
}

export type DistributionsViewProps = Props;
