import {
  Button,
  Container,
  HStack,
  Heading,
  Input,
  InputProps,
  Stack,
  Text,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import { routes } from '@blockpulse3/data/shared';
import { TwoFactorStatus, useVerify2FaMutation } from '@blockpulse3/graphql/hooks';
import { BrandIcon } from '@blockpulse3/ui/brand-theme';
import { useErrorToast } from '@blockpulse3/ui/commons';
import { useAuthUser } from '@blockpulse3/web-client/auth';

type Props = {
  email: string;
  /* ** Callback on reset link button ** */
  onReset: () => void;
};

export function Check2FA({ email, onReset }: Props): JSX.Element {
  const t = useTranslations();

  const [resendCountdown, setResendCountdown] = useState(15);

  const errorToast = useErrorToast();

  const navigate = useNavigate();

  const { refetch } = useAuthUser();
  const [verify2FA] = useVerify2FaMutation();

  useEffect(() => {
    const interval = setInterval(() => {
      setResendCountdown((prev) => (prev > 0 ? prev - 1 : 0));
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const submit2FA = (): void => {
    if (!email) return;

    const otp = Array.from(
      { length: 6 },
      (_, i) => (document.getElementById(`otp-${i + 1}`) as HTMLInputElement).value,
    ).join('');

    if (otp.length !== 6) {
      errorToast({ title: t('TwoFactorInvalidVerificationCode') });
      return;
    }

    verify2FA({
      variables: { verify2FAInput: { email, otp } },
      onCompleted: (data) => {
        if (data.verify2FA.access_token) {
          localStorage.setItem('token', data.verify2FA.access_token);
          refetch();
        } else if (data.verify2FA.two_factor_status === TwoFactorStatus.EXPIRED) {
          errorToast({ title: t('TwoFactorExpiredVerificationCode') });
        } else if (data.verify2FA.two_factor_status === TwoFactorStatus.UNVERIFIED) {
          errorToast({ title: t('TwoFactorInvalidVerificationCode') });
        }
      },
      onError: () => {
        navigate(routes.login.href);
      },
    });
  };

  const focusToNext = (e: React.KeyboardEvent<HTMLInputElement>): void => {
    if (/[0-9]/.test(e.key) && e.currentTarget.value.length === 1) {
      const nextId = `otp-${parseInt(e.currentTarget.id.split('-')[1]) + 1}`;
      if (nextId !== 'otp-7') {
        (document.getElementById(nextId) as HTMLInputElement).focus();
      }
    } else if (e.currentTarget.id === 'otp-6' && e.key === 'Enter') {
      submit2FA();
    }
  };

  const pasteCode = (e: React.ClipboardEvent<HTMLInputElement>): void => {
    e.preventDefault();
    const paste = e.clipboardData.getData('text').replace(/\D/g, '');
    if (paste.length !== 6) {
      errorToast({ title: t('TwoFactorInvalidVerificationCode') });
      return;
    }

    Array.from({ length: 6 }, (_, i) => {
      (document.getElementById(`otp-${i + 1}`) as HTMLInputElement).value = paste[i];
    });

    setTimeout(() => {
      submit2FA();
    }, 300);
  };

  const handleResend = (): void => {
    setResendCountdown(15);
    onReset();
  };

  const inputProps: InputProps = {
    fontSize: 'xl',
    h: '12',
    maxLength: 1,
    p: '0',
    textAlign: 'center',
    type: 'text',
    w: '10',
    onKeyUp: focusToNext,
  };

  return (
    <Container bg={{ base: 'transparent', md: 'bg-surface' }} maxW="lg">
      <Stack align="center" spacing="12">
        <Stack align="center" spacing="6">
          <BrandIcon alignSelf="flex-start" />
          <Heading alignSelf="start" size="lg">
            {t('TwoFactorCheckCode')}
          </Heading>
          <Text maxWidth="345px">
            {t.rich('TwoFactorCheckCodeDescription', {
              important: (chunk) => (
                <Text as="span" fontWeight="bold">
                  {chunk}
                </Text>
              ),
              email,
            })}
          </Text>
          <HStack spacing="4">
            <Input autoFocus={true} id="otp-1" {...inputProps} onPaste={pasteCode} />
            <Input id="otp-2" {...inputProps} />
            <Input id="otp-3" {...inputProps} />
            <Text fontWeight="700">-</Text>
            <Input id="otp-4" {...inputProps} />
            <Input id="otp-5" {...inputProps} />
            <Input id="otp-6" {...inputProps} />
          </HStack>
          <Button w="full" onClick={submit2FA}>
            {t('LogIn')}
          </Button>
          <Button isDisabled={resendCountdown > 0} variant="link" onClick={handleResend}>
            <Text>{t('TwoFactorVerificationResendCode')}</Text>
            {resendCountdown > 0 && (
              <Text fontSize="sm" fontWeight="normal">
                &nbsp;({resendCountdown}s)
              </Text>
            )}
          </Button>
        </Stack>
      </Stack>
    </Container>
  );
}
