import { Card, CardBody, Stack, useDisclosure } from '@chakra-ui/react';
import { useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import { MemberFilterType } from '@blockpulse3/data/shared';
import {
  GetMembersDocument,
  GetMembersQuery,
  GetMembersQueryVariables,
  Member,
  MemberEntityInfosFragment,
  MemberStatus,
  MembersFilterInput,
  useDeleteMemberMutation,
} from '@blockpulse3/graphql/hooks';
import { ConfirmModal, useErrorToast, useSuccessToast } from '@blockpulse3/ui/commons';
import { usePagination } from '@blockpulse3/ui/ui-hooks';
import { useAuthUser } from '@blockpulse3/web-client/auth';

import { PAGE_SIZE } from '../utils';
import { NewSpaceMemberForm, UpdateSpaceMemberForm } from './SpaceMemberForm';
import { SpaceMembersControls } from './SpaceMembersControls';
import { SpaceMembersTable } from './SpaceMembersTable';

type Props = unknown;

export function SpaceMembers(): JSX.Element {
  const t = useTranslations();

  const confirmRef = useRef(null);

  const { spaceId: authSpaceId = '' } = useAuthUser();
  const { spaceId = authSpaceId } = useParams();

  const [filters, setFilters] = useState<MembersFilterInput[]>([]);

  const errorToast = useErrorToast();
  const successToast = useSuccessToast();

  const createMemberModal = useDisclosure();
  const updateMemberModal = useDisclosure();
  const deleteMemberModal = useDisclosure();

  const [updateMemberId, setUpdateMemberId] = useState<Member['id'] | null>(null);
  const [deleteMemberId, setDeleteMemberId] = useState<Member['id'] | null>(null);

  const [deleteMember] = useDeleteMemberMutation();

  const paginationProps = usePagination<
    GetMembersQuery,
    GetMembersQueryVariables,
    MemberEntityInfosFragment
  >({
    queryDocument: GetMembersDocument,
    queryOptions: {
      variables: {
        spaceId,
        first: PAGE_SIZE,
        filterBy: filters,
      },
      skip: !spaceId,
      fetchPolicy: 'cache-and-network',
    },
    dataName: 'members',
    pageSize: PAGE_SIZE,
  });
  const { refetch, reset } = paginationProps;

  const handleMemberAddModal = (): void => {
    createMemberModal.onOpen();
  };

  const handleMemberUpdateModal = (memberId: Member['id']): void => {
    setUpdateMemberId(memberId);
    updateMemberModal.onOpen();
  };

  const handleMemberUpdateSuccess = (): void => {
    setUpdateMemberId(null);
    updateMemberModal.onClose();
    refetch();
  };

  const handleMemberUpdateError = (): void => {
    setUpdateMemberId(null);
    updateMemberModal.onClose();
  };

  const handleMemberDeleteModal = (memberId: Member['id']): void => {
    setDeleteMemberId(memberId);
    deleteMemberModal.onOpen();
  };

  const handleMemberDelete = (): void => {
    if (!deleteMemberId) return;

    deleteMember({
      variables: {
        memberId: deleteMemberId,
      },
      onCompleted: () => {
        successToast({ title: t('DeleteMemberSuccess') });
        setDeleteMemberId(null);
        deleteMemberModal.onClose();
        refetch();
      },
      onError: () => {
        errorToast({ title: t('DeleteMemberError') });
        setDeleteMemberId(null);
        deleteMemberModal.onClose();
      },
    });
  };

  const handleSpaceMemberAddSuccess = (memberStatus: MemberStatus): void => {
    switch (memberStatus) {
      case MemberStatus.INVITATION_SENT: {
        successToast({ title: t('MemberInvited') });
        break;
      }
      case MemberStatus.VALIDATED: {
        successToast({ title: t('MemberAdded') });
        break;
      }
      default: {
        errorToast({ title: t('MemberInvitedError') });
        break;
      }
    }
    createMemberModal.onClose();
    refetch();
  };

  const handleSearchInputSubmit = (value: string): void => {
    const isInputAndFilterSearchEmpty =
      value === '' && !filters.some((filter) => filter.name === 'searchValue');
    if (isInputAndFilterSearchEmpty) return;

    resetSpecificFilter('searchValue', MemberFilterType.CUSTOM, value);
  };

  const resetSpecificFilter = (
    filterName: string,
    filterType: MemberFilterType,
    value: string,
  ): void => {
    const currentFilterWithoutFilterName = filters.filter((filter) => filter.name !== filterName);
    const newFilter: MembersFilterInput = {
      name: filterName,
      type: filterType,
      value,
    };
    reset();
    setFilters([...currentFilterWithoutFilterName, newFilter]);
  };

  return (
    <Card>
      <CardBody as={Stack} spacing="4">
        <SpaceMembersControls
          onMemberAdd={handleMemberAddModal}
          onSearchSubmit={handleSearchInputSubmit}
        />
        <SpaceMembersTable
          onRowDeleteClick={handleMemberDeleteModal}
          onRowUpdateClick={handleMemberUpdateModal}
          {...paginationProps}
        />
      </CardBody>
      <NewSpaceMemberForm
        isOpen={createMemberModal.isOpen}
        onClose={createMemberModal.onClose}
        onSubmitSuccess={handleSpaceMemberAddSuccess}
      />
      {updateMemberId && (
        <UpdateSpaceMemberForm
          isOpen={updateMemberModal.isOpen}
          memberId={updateMemberId}
          onClose={updateMemberModal.onClose}
          onSubmitError={handleMemberUpdateError}
          onSubmitSuccess={handleMemberUpdateSuccess}
        />
      )}
      <ConfirmModal
        isOpen={deleteMemberModal.isOpen}
        leastDestructiveRef={confirmRef}
        subtitle={t('DefinitiveAction')}
        title={t('ConfirmMemberDeleteTitle')}
        onClose={deleteMemberModal.onClose}
        onConfirm={handleMemberDelete}
      />
    </Card>
  );
}

export type SpaceMembersProps = Props;
