import {
  Badge,
  Box,
  Button,
  Card,
  CardBody,
  CardHeader,
  HStack,
  Heading,
  Hide,
  Icon,
  Skeleton,
  Stack,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
} from '@chakra-ui/react';
import { CollectionIcon } from '@heroicons/react/outline';
import { useEffect, useRef, useState } from 'react';
import { generatePath, resolvePath, useParams } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import { noop, routes } from '@blockpulse3/data/shared';
import {
  BankDistributionStatus,
  TransferStatus,
  useGenerateSwanAuthUrlLazyQuery,
  useGetBankAccountByIdentityIdQuery,
  useGetBankAccountMemberQuery,
  useGetBankDistributionQuery,
  useGetBankTransfersByDistributionQuery,
} from '@blockpulse3/graphql/hooks';
import { formatNumberCurrency } from '@blockpulse3/helpers';
import {
  CompanyIdentityAvatar,
  ErrorQueryCard,
  IdentityAvatar,
  LinkButton,
  TableContainer,
} from '@blockpulse3/ui/commons';
import { useBadge } from '@blockpulse3/ui/ui-hooks';
import { useAuthUser, useIdentity } from '@blockpulse3/web-client/auth';

export function DistributionView(): JSX.Element {
  const t = useTranslations();
  const i18nBankDistributionType = useTranslations('BankDistributionType');

  const popupWindowRef = useRef<Window | null>(null);

  const { companyId = '', distributionId = '' } = useParams();

  const { user } = useAuthUser();
  const { identityId } = useIdentity();

  const [isTransferInProgress, setIsTransferInProgress] = useState(false);
  const [isPollingStarted, setIsPollingStarted] = useState(false);
  const [targetedTransferId, setTargetedTransferId] = useState<string>('');
  const [executedTransferIds, setExecutedTransferIds] = useState<string[]>([]);

  const [generateSwanAuthUrl] = useGenerateSwanAuthUrlLazyQuery();

  const {
    data: distributionData,
    loading: distributionLoading,
    refetch: refetchDistribution,
  } = useGetBankDistributionQuery({
    variables: { bankDistributionId: distributionId },
    skip: !distributionId,
  });
  const bankDistribution = distributionData?.bankDistribution;

  const { data, loading, error, startPolling, stopPolling } =
    useGetBankTransfersByDistributionQuery({
      variables: { bankDistributionId: distributionId },
      fetchPolicy: 'cache-and-network',
      skip: !distributionId,
    });

  const { data: bankAccountData, refetch: refetchBankAccount } = useGetBankAccountByIdentityIdQuery(
    {
      variables: { identityId },
      skip: !identityId,
    },
  );
  const bankAccount = bankAccountData?.getBankAccountByIdentityId;

  const bankAccountMemberReq = useGetBankAccountMemberQuery({
    variables: {
      bankAccountId: bankAccount?.id || '',
      individualIdentityId: user?.individualIdentity?.id || '',
    },
    skip: !bankAccount?.id || !user?.individualIdentity,
  });
  const isBankMember = !!bankAccountMemberReq.data?.getBankAccountMember?.bankDashboardUrl;

  useEffect(() => {
    if (isPollingStarted) {
      const bankTransfers = data?.getBankTransfersByDistribution || [];

      const allPaymentStepHandled = !bankTransfers?.some(
        (bankTransfer) =>
          executedTransferIds.includes(bankTransfer.id) &&
          bankTransfer.status === TransferStatus.DRAFT,
      );

      if (!isTransferInProgress) {
        setIsTransferInProgress(true);
      }

      const timeout = setTimeout(() => {
        stopPolling();
        setIsPollingStarted(false);
        setIsTransferInProgress(false);
        setTargetedTransferId('');
        setExecutedTransferIds([]);
        refetchDistribution();
        refetchBankAccount();
      }, 120_000);

      if (allPaymentStepHandled) {
        stopPolling();
        setIsPollingStarted(false);
        setIsTransferInProgress(false);
        setTargetedTransferId('');
        setExecutedTransferIds([]);
        refetchDistribution();
        refetchBankAccount();
      }

      return (): void => clearTimeout(timeout);
    }
    return noop;
  }, [
    data,
    isPollingStarted,
    isTransferInProgress,
    targetedTransferId,
    stopPolling,
    refetchBankAccount,
  ]);

  useEffect(() => {
    const interval = setInterval(() => {
      // Reset loader when popup window is closed
      if (popupWindowRef?.current?.closed && !isPollingStarted) {
        popupWindowRef.current = null;
        setIsTransferInProgress(false);
      }
    }, 500);

    return (): void => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { getBadge } = useBadge(
    null,
    [
      { value: TransferStatus.DRAFT, label: t('Pending'), color: 'yellow' },
      { value: TransferStatus.UPCOMING, label: t('Pending'), color: 'yellow' },
      { value: TransferStatus.PENDING, label: t('Emitted'), color: 'blue' },
      { value: TransferStatus.BOOKED, label: t('Executed'), color: 'green' },
      { value: TransferStatus.CANCELED, label: t('Canceled'), color: 'red' },
      { value: TransferStatus.REJECTED, label: t('Rejected'), color: 'red' },
    ],
    { label: t('Pending'), color: 'yellow' },
  );

  if (loading || distributionLoading) {
    return <Skeleton h="65px" />;
  }

  if (error || !bankDistribution || !bankAccount) {
    return <ErrorQueryCard h="65px" />;
  }

  const transfers = data?.getBankTransfersByDistribution || [];
  const hasPendingCreditTransfer = bankDistribution.status !== BankDistributionStatus.COMPLETED;
  const pendingTransferedAmount = bankDistribution.pendingTransferedAmount;

  const TRANSFER_LIMIT = 100;
  const transferLimitExceeded = bankDistribution.pendingTransferCount > TRANSFER_LIMIT;

  // Function triggered by child popup window on close
  window.closePopup = async function (): Promise<void> {
    if (!popupWindowRef.current) return;
    setIsPollingStarted(true);
    startPolling(1000);
    popupWindowRef.current.close();
  };

  const handleInitiateQueuedPayments = (transferId?: string): void => {
    if (popupWindowRef?.current && !popupWindowRef?.current?.closed) return;

    // Set or reset targeted subscription id
    setTargetedTransferId(transferId || '');

    if (transferId) {
      setExecutedTransferIds([transferId]);
    } else {
      setExecutedTransferIds(transfers.slice(0, TRANSFER_LIMIT).map((tr) => tr.id));
    }

    const redirectUrlState = resolvePath(
      transferId
        ? generatePath(routes.bank.initiatePayments.distribution.transfer.full, {
            distributionId,
            transferId,
          })
        : generatePath(routes.bank.initiatePayments.distribution.full, { distributionId }),
      companyId ? generatePath(routes.company.href, { companyId }) : routes.me.href,
    ).pathname;
    generateSwanAuthUrl({
      variables: {
        identityId,
        redirectUrlState,
      },
      onCompleted: (data) => {
        setIsTransferInProgress(true);
        const swanAuthorizationUrl = data.generateSwanAuthUrl;

        const popupWidth = 500;
        const popupHeight = 700;

        // Fixes dual-screen position                             Most browsers      Firefox
        const dualScreenLeft = window.screenLeft !== undefined ? window.screenLeft : window.screenX;
        const dualScreenTop = window.screenTop !== undefined ? window.screenTop : window.screenY;

        const width =
          window.innerWidth || document.documentElement.clientWidth || window.screen.width;
        const height =
          window.innerHeight || document.documentElement.clientHeight || window.screen.height;

        const systemZoom = width / window.screen.availWidth;
        const left = (width - popupWidth) / 2 / systemZoom + dualScreenLeft;
        const top = (height - popupHeight) / 2 / systemZoom + dualScreenTop;

        popupWindowRef.current = window.open(
          swanAuthorizationUrl,
          'SwanOAuthPopup',
          `width=${popupWidth},height=${popupHeight},left=${left},top=${top}`,
        );
      },
    });
  };

  const { bookedBalance = 0, availableBalance = 0 } = bankAccount;
  const pendingAmountGreaterThanBalance = pendingTransferedAmount > availableBalance;

  const distributionsRoute = `../${
    companyId ? routes.company.distributions.href : routes.me.payments.href
  }`;

  return (
    <Stack spacing="6">
      <LinkButton
        label={t(companyId ? 'Distribution' : 'Payment', { nb: 2 })}
        route={distributionsRoute}
      />
      <Card overflow="hidden">
        <Stack direction={{ base: 'column', md: 'row' }}>
          <Hide below="md">
            <CompanyIdentityAvatar
              boxSize="32"
              src={bankDistribution.operation?.company?.profilePicture}
            />
          </Hide>
          <Stack
            alignItems="flex-start"
            justifyContent="center"
            pb="2"
            pt={{ base: '5', md: '2' }}
            px="5"
            spacing="1"
          >
            <Text color="gray.500" fontSize="xs" fontWeight="semibold" textTransform="uppercase">
              {i18nBankDistributionType(bankDistribution.type)}
            </Text>
            <Heading fontWeight="bold">{bankDistribution.name}</Heading>
            {/* <Badge colorScheme={badge.color}>{badge.label}</Badge> */}
          </Stack>
        </Stack>
      </Card>
      <Card>
        <CardBody>
          <Stack direction={{ base: 'column', md: 'row' }} spacing={{ base: '4', md: '8' }}>
            <Box bg="gray.50" color="white" p="4" w="full">
              <Text color="gray.500" fontSize="md" fontWeight="500">
                {t('TotalAvailableAmountOnAccount')}
              </Text>
              <Text color="gray.800" fontSize="lg" fontWeight="700">
                {formatNumberCurrency(availableBalance)}
              </Text>
            </Box>
            <Box bg="gray.50" color="white" p="4" w="full">
              <Text color="gray.500" fontSize="md" fontWeight="500">
                {t('TotalBookedAmountOnAccount')}
              </Text>
              <Text color="gray.800" fontSize="lg" fontWeight="700">
                {formatNumberCurrency(bookedBalance)}
              </Text>
            </Box>
            <Box bg="gray.50" color="white" p="4" w="full">
              <Text color="gray.500" fontSize="md" fontWeight="500">
                {t('TotalAmountOfPendingTransfers')}
              </Text>
              <Text color="gray.800" fontSize="lg" fontWeight="700">
                {formatNumberCurrency(pendingTransferedAmount)}
              </Text>
            </Box>
          </Stack>
        </CardBody>
      </Card>
      <Card>
        <CardHeader
          as={Stack}
          direction={{ base: 'column', md: 'row' }}
          justifyContent="space-between"
        >
          <Heading size="md">{t('BankTransfer', { nb: 2 })}</Heading>
          {hasPendingCreditTransfer && (
            <Tooltip
              hasArrow
              isDisabled={!pendingAmountGreaterThanBalance}
              label={t('InsufficientBalanceForAllPendingTransfers')}
              placement="top"
            >
              <Button
                isLoading={isTransferInProgress && !targetedTransferId}
                variant="secondary"
                width={{ base: 'fit-content' }}
                isDisabled={
                  !isBankMember ||
                  pendingAmountGreaterThanBalance ||
                  (isTransferInProgress && !!targetedTransferId)
                }
                onClick={(): void => handleInitiateQueuedPayments()}
              >
                <Text>
                  {transferLimitExceeded
                    ? t('ExecuteHundredFirstPayments')
                    : t('ExecuteAllQueuedPayments')}
                </Text>
              </Button>
            </Tooltip>
          )}
        </CardHeader>
        <CardBody>
          {transfers.length === 0 ? (
            <Stack h="100px" layerStyle="emptyState">
              <Icon as={CollectionIcon} boxSize="40px" color="gray.500" />
              <Text>{t('NoPendingQueuedPayments')}</Text>
            </Stack>
          ) : (
            <TableContainer maxHeight="none">
              <Table variant="striped">
                <Thead>
                  <Tr>
                    <Th>{t('RecipientName')}</Th>
                    <Th>{t('Amount', { nb: 1 })}</Th>
                    <Th>{t('TransferStatus')}</Th>
                    <Th></Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {transfers.map((transfer) => {
                    const paymentStatus = transfer.status;
                    const paymentBadge = getBadge(paymentStatus);
                    const hasDraftStatus = paymentStatus === TransferStatus.DRAFT;
                    const amountGreaterThanBalance = transfer.amount > availableBalance;

                    const isTargetedTransfer = targetedTransferId === transfer.id;
                    return (
                      <Tr key={transfer.id} role="button">
                        <Td>
                          {transfer.creditorIdentity ? (
                            <HStack spacing="2.5">
                              <IdentityAvatar identity={transfer.creditorIdentity} />
                              <Stack spacing="1">
                                <Text fontWeight="600">{transfer.creditorIdentity?.name}</Text>
                                {transfer.creditorIdentity?.identifier && (
                                  <Text color="gray.500" fontWeight="400">
                                    {transfer.creditorIdentity?.identifier}
                                  </Text>
                                )}
                              </Stack>
                            </HStack>
                          ) : (
                            <Text fontWeight="600" textAlign="left">
                              -
                            </Text>
                          )}
                        </Td>
                        <Td fontWeight="600">{formatNumberCurrency(transfer.amount)}</Td>
                        <Td>
                          <Badge colorScheme={paymentBadge.color}>{paymentBadge.label}</Badge>
                        </Td>
                        <Td textAlign="right">
                          {hasDraftStatus && (
                            <Tooltip
                              hasArrow
                              isDisabled={!amountGreaterThanBalance}
                              label={t('InsufficientBalanceForTransfer')}
                              placement="top"
                            >
                              <Button
                                isLoading={isTransferInProgress && isTargetedTransfer}
                                variant="secondary"
                                isDisabled={
                                  !isBankMember ||
                                  amountGreaterThanBalance ||
                                  (isTransferInProgress && !isTargetedTransfer)
                                }
                                onClick={(): void => handleInitiateQueuedPayments(transfer.id)}
                              >
                                {t('ExecuteBankTransfer')}
                              </Button>
                            </Tooltip>
                          )}
                        </Td>
                      </Tr>
                    );
                  })}
                </Tbody>
              </Table>
            </TableContainer>
          )}
        </CardBody>
      </Card>
    </Stack>
  );
}
