import { Box, ColorProps, HStack, Text, Tooltip } from '@chakra-ui/react';
import { useTranslations } from 'use-intl';

import { AssetType } from '@blockpulse3/graphql/hooks';
import { formatNumberInt } from '@blockpulse3/helpers';

type Props = {
  /* ** Type of the Asset (AO, BSA...) ** */
  type: AssetType;
  /* ** Number of assets, if null, just info type rendered ** */
  value: number | null;
  /* ** Size of the badge ** */
  size?: string;
  /* ** Is badge grayed: for not attributed assets ** */
  grayed?: boolean;
  /* ** Count of asset (for BSA AIR use case) */
  assetCount?: number;
  tooltip?: string;
};

const ASSET_COLOR_MAP: Record<AssetType, ColorProps['color']> = {
  BOND: 'green.500',
  ORDINARY_SHARE: 'indigo.700',
  PREFERRED_SHARE: 'purple.600',
  BSA: 'orange.600',
  BSA_AIR: 'pink.600',
  BSPCE: 'teal.700',
  AGA: 'npurple.600',
};

/**
 * AssetBadge.
 * Simple component, rendering a badge of desired asset type.
 *
 * @param {Props}
 * @returns {JSX.Element}
 */
export function AssetBadge({
  type,
  value,
  size = 'sm',
  grayed = false,
  assetCount = 1,
  tooltip,
}: Props): JSX.Element {
  const i18nAssetCount = useTranslations('AssetTypeCounts');
  const i18nAssetValue = useTranslations('AssetTypeValues');

  let smallSize = 'xs';
  if (size === 'xl') {
    smallSize = 'md';
  }

  return (
    <Tooltip hasArrow isDisabled={!tooltip} label={tooltip} placement="top" textAlign="center">
      <Box bg="gray.100" px="2" py="0.5" rounded="md" width="fit-content">
        <HStack>
          <Text color={grayed ? 'gray.500' : ASSET_COLOR_MAP[type]} fontSize={size}>
            <Text
              as="span"
              display="block"
              fontSize={smallSize}
              fontWeight="600"
              textAlign="center"
            >
              {value || value === 0
                ? i18nAssetCount.rich(type, {
                    assetCount: value === 0 ? value : assetCount,
                    nb: value,
                    formattedNb: value || value === 0 ? formatNumberInt(value) : '',
                    important: (chunk) => (
                      <Text as="span" fontSize={size} fontWeight="700">
                        {chunk}
                      </Text>
                    ),
                  })
                : i18nAssetValue(type, { nb: 1 })}
            </Text>
          </Text>
        </HStack>
      </Box>
    </Tooltip>
  );
}

export type AssetBadgeProps = Props;
