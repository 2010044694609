import {
  Alert,
  AlertIcon,
  AlertTitle,
  Button,
  Divider,
  FormControl,
  FormLabel,
  HStack,
  Icon,
  Input,
  ModalBody,
  Stack,
  Text,
} from '@chakra-ui/react';
import { DocumentTextIcon } from '@heroicons/react/outline';
import { yupResolver } from '@hookform/resolvers/yup';
import axios from 'axios';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslations } from 'use-intl';

import { IntlCSVParseError } from '@blockpulse3/data/locales/types';
import { CsvCellError, CsvError, Exceptions, FormErrors } from '@blockpulse3/data/shared';
import { BankDistributionType } from '@blockpulse3/graphql/hooks';
import { formatNumberCurrency } from '@blockpulse3/helpers';
import {
  CsvDataTable,
  DownloadCsvTemplate,
  DropzoneFiles,
  DropzoneInput,
  ErrorMessage,
  ResponsiveModalFooter,
  useErrorToast,
  useSuccessToast,
} from '@blockpulse3/ui/commons';
import { useIdentity } from '@blockpulse3/web-client/auth';

import { createBankDistributionSchema } from '../schema';

type Props = {
  bankDistributionType: BankDistributionType;
  onClose: () => void;
  onComplete: () => void;
};

export function CreateDistributionForm({
  bankDistributionType,
  onClose,
  onComplete,
}: Props): JSX.Element {
  const t = useTranslations();
  const i18nCSVParseError = useTranslations('CSVParseError');
  const i18nBankDistributionType = useTranslations('BankDistributionType');

  const { identityId } = useIdentity();

  const [files, setFiles] = useState<DropzoneFiles>({});
  const [isUploading, setIsUploading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [csvRecipientsErrors, setCsvRecipientsErrors] = useState<CsvCellError[]>([]);
  const [csvRecipientsData, setCsvRecipientsData] = useState<Record<string, string>[]>([]);
  const [csvTotals, setCsvTotals] = useState<{ totalAmount: number; totalRow: number }>({
    totalAmount: 0,
    totalRow: 0,
  });

  const successToast = useSuccessToast();
  const errorToast = useErrorToast();

  const { register, control, handleSubmit, formState, setError, setValue, clearErrors, getValues } =
    useForm({
      defaultValues: { distributionName: '', recipients: '' },
      resolver: yupResolver(createBankDistributionSchema),
    });

  useEffect(() => {
    setValue(
      'distributionName',
      `${i18nBankDistributionType(bankDistributionType)} ${dayjs().format('MM-YYYY')}`,
    );
  }, [bankDistributionType]);

  const handleFileDrop = async (acceptedFiles: File[]): Promise<void> => {
    clearErrors('recipients');

    const formData = new FormData();
    formData.append('recipients', acceptedFiles[0]);
    formData.append('identityId', identityId);

    setIsUploading(true);
    await axios
      .post(process.env['NX_API_CONTROLLER_ENDPOINT'] + '/distributions/checkCSV', formData, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'multipart/form-data',
        },
      })
      .then(({ data }) => {
        setValue('recipients', 'file.csv', { shouldValidate: true });
        setFiles({ [acceptedFiles[0].name]: acceptedFiles[0] });
        setCsvRecipientsData(data.csvData);
        setCsvTotals({ totalAmount: data.totalAmount, totalRow: data.totalRow });
        setIsUploading(false);
        setCsvRecipientsErrors([]);
      })
      .catch((err: unknown) => {
        if (axios.isAxiosError(err)) {
          const error = err?.response?.data as CsvError;
          const errorMessage = error?.message ?? error.message;
          const errorCsvData = error?.data || [];
          const errorCsvCells = error?.cellErrors || [];

          setCsvRecipientsData(errorCsvData);
          setCsvRecipientsErrors(errorCsvCells);

          if (!error.code && errorMessage === Exceptions.MissingRequiredProperties) {
            setError('recipients', {
              type: 'custom',
              message: t('SomeRequiredInfoMissing'),
            });
          } else {
            const code = error.code || 'DefaultError';
            setError('recipients', {
              type: 'custom',
              message: i18nCSVParseError(code as IntlCSVParseError),
            });
          }
        } else {
          setError('recipients', {
            type: 'custom',
            message: FormErrors.DropzoneInvalidTemplate,
          });
        }
        setIsUploading(false);
      });
  };

  const handleCSVSubmit = async (): Promise<void> => {
    const formData = new FormData();
    formData.append('recipients', files[Object.keys(files)[0]]);
    formData.append('identityId', identityId);
    formData.append('name', getValues('distributionName'));
    formData.append('type', bankDistributionType);

    setIsLoading(true);
    await axios
      .post(process.env['NX_API_CONTROLLER_ENDPOINT'] + '/distributions/create', formData, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'multipart/form-data',
        },
      })
      .then(() => {
        successToast({ title: t('BankDistributionCreatedSuccessfully') });
        onComplete();
        setIsLoading(false);
        onClose();
      })
      .catch((err: unknown) => {
        errorToast({ title: t('BankDistributionCreatedError') });
        setIsLoading(false);
      });
  };

  const handleFileDelete = (): void => {
    setValue('recipients', '');
    setCsvRecipientsData([]);
    setCsvRecipientsErrors([]);
    setFiles({});
    setCsvTotals({ totalAmount: 0, totalRow: 0 });
  };

  const handleFileError = (): void => {
    setError('recipients', { message: FormErrors.InvalidFileFormat });
  };

  const cantValidate =
    !!(formState.errors?.recipients || csvRecipientsErrors?.length > 0) &&
    !csvRecipientsData?.length;

  return (
    <>
      <ModalBody>
        <Stack spacing="4">
          <Alert status="info">
            <AlertIcon />
            <AlertTitle>
              <Text>{t('DistributionsAlert')}</Text>
              <Text>{t('DistributionsPEAWarning')}</Text>
              <br />
              <DownloadCsvTemplate path={'/assets/distribution_template.csv'} />
            </AlertTitle>
          </Alert>
          <form id="create-bank-distribution" onSubmit={handleSubmit(handleCSVSubmit)}>
            <Stack spacing="4">
              <FormControl isInvalid={!!formState.errors?.distributionName}>
                <FormLabel htmlFor="distributionName">{t('DistributionsLabel')}</FormLabel>
                <Input id="distributionName" type="text" {...register('distributionName')} />
                <ErrorMessage error={formState.errors?.distributionName} />
              </FormControl>
              <Controller
                control={control}
                name="recipients"
                render={(): JSX.Element => (
                  <FormControl isInvalid={!!formState.errors?.recipients}>
                    <FormLabel>{t('SelectDocument')}</FormLabel>
                    <DropzoneInput
                      accept={{ 'text/csv': [] }}
                      files={files}
                      icon={<Icon as={DocumentTextIcon} boxSize="42px" strokeWidth="1px" />}
                      isLoading={isUploading}
                      subTitle="CSV de 5M"
                      onDelete={handleFileDelete}
                      onDrop={handleFileDrop}
                      onError={handleFileError}
                    />
                    <ErrorMessage error={formState.errors?.recipients} />
                  </FormControl>
                )}
              />
            </Stack>
          </form>
          {csvTotals.totalRow > 0 && (
            <HStack justifyContent="space-between">
              <HStack>
                <Text>
                  {t.rich('TransferCount', {
                    nb: csvTotals.totalRow,
                    important: (chunks) => <Text as="b">{chunks}</Text>,
                  })}
                </Text>
              </HStack>
              <HStack>
                <Text>{t('TotalAmount')}:</Text>
                <Text fontWeight="600">{formatNumberCurrency(csvTotals.totalAmount)}</Text>
              </HStack>
            </HStack>
          )}
          <CsvDataTable cellErrors={csvRecipientsErrors} csvData={csvRecipientsData} />
        </Stack>
      </ModalBody>
      <Divider />
      <ResponsiveModalFooter>
        <Button
          form="create-bank-distribution"
          isDisabled={isUploading || cantValidate}
          isLoading={isLoading}
          type="submit"
        >
          {t('Validate')}
        </Button>
      </ResponsiveModalFooter>
    </>
  );
}
